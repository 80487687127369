import React, { ChangeEventHandler, ReactNode, useEffect, useState } from 'react';
import { Image, Card, Button, Spinner, Modal, FormControl } from 'react-bootstrap';
import { Agent, AgentChildComponentProps } from '../../pages/agentHomePage';


type AgentDeleteCardProps = {

} & AgentChildComponentProps

type AgentUsage = {
    platform: string;
    summary: string;
    usages: string[];
}


export function _AgentDeleteCard(props: AgentDeleteCardProps) {

    // get agent usages
    const [agentUsages, setAgentUsages] = useState<any[]>([])
    const [loading, setLoading] = useState(true)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [deleteAgentValidationText,  setDeleteAgentValidationText] = useState("")
    const [deleting, setDeleting] = useState(false)

    useEffect(() => {
        const loadAgentUsages = async() => {
            // get agent info for confirmation before showing dialog
            try {
                const usages = await props.multiverse.get<AgentUsage[]>(`/v2/agents/usages/${props.agent_id}`);
                setAgentUsages(usages)
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        loadAgentUsages()
    }
    , [props.shouldReload])


    const onDeleteAgentClick = async() => {
        setShowDeleteDialog(true)
        setDeleteAgentValidationText ("")
    }

    const onDeleteAgentValidationTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDeleteAgentValidationText(e.target.value)
    }

    const handleDeleteDialogClose = () => {
        setShowDeleteDialog(false)
    }

    const handleDeleteDialogConfirm = async () => {
        setDeleting(true)
        try {
            await props.multiverse.del(`/v2/agents/${props.agent_id}`);
        } catch (err) {

        } finally {
            //leave this for the list refresh
            /*this.setState({
                isLoading: false
            })*/
        }
        // redirect to agents page
        setDeleting(false)
        setShowDeleteDialog(false)
        props.history.push('/agents')
    }

    const render_delete_dialog = () => {
        return (
            <Modal show={showDeleteDialog} onHide={handleDeleteDialogClose}>
                
                {deleting && <><Modal.Header style={{ backgroundColor: "indianred" }} closeButton>
                    <Modal.Title>Deleting Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Spinner animation="border" />
                </Modal.Body></>}
                {!deleting && <>
                <Modal.Header style={{ backgroundColor: "indianred" }} closeButton>
                    <Modal.Title>Delete Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Warning! Deleting an agent is a permanent action. Once done, you won't be able to get it back.</p>
                    <p>This agent is currently used in</p>
                    <pre>{
                        agentUsages?.map((usage) => (
                            <div key={usage.platform}>
                                <b>{usage.platform}</b>
                                <p>{usage.summary}</p>
                                <ul>
                                    {usage.usages.map((usage:any) => (
                                        <li key={usage}>{usage}</li>
                                    ))}
                                </ul>
                            </div>
                        ))
                        }</pre>
                    <p>If you're sure, type <span className="font-weight-bold">{props.agent?.display_name}</span> here:</p>
                    <FormControl
                        placeholder=""
                        value={deleteAgentValidationText}
                        onChange={onDeleteAgentValidationTextChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteDialogClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={deleteAgentValidationText !== props.agent?.display_name} onClick={handleDeleteDialogConfirm}>
                        Delete Permanently
                    </Button>
                </Modal.Footer></>}
            </Modal>
        )
    }

    
    return ( 
        <Card className="mt-2 discovery-content-card">
            {render_delete_dialog()}
            <Card.Header>Delete Agent</Card.Header>
            <Card.Body>
                {loading && <Spinner animation="border" />}
                {!loading && <>
                <p><b>Deleting this agent is a permanent action, and cannot be undone!</b></p>
                <p>
                    Deleting it will do the following:
                    <ul>
                        <li>Remove the agent from all platforms, metaverses and locations</li>
                        <li>Remove all memory and interactions associated with the agent</li>
                        <li>Remove all chat history associated with the agent</li>
                    </ul>
                </p>
                <p>
                    Current usages include:
                    <ul>
                        {agentUsages.map((usage) => (
                            <li key={usage.platform}>{usage.platform} - {usage.summary}</li>
                        ))}
                    </ul>
                </p>
                <p><Button variant="danger" onClick={() => {
                    onDeleteAgentClick()
                }}>Delete Agent!</Button></p>
                </>}
            </Card.Body>
        </Card>
    )
}
export const AgentDeleteCard =_AgentDeleteCard;

export default AgentDeleteCard;
