import React, { useEffect, useState } from 'react';
import { Image, Accordion, Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import PageTable, { PageTableCell, PageTableRow } from '../pageTable';
import api from '../../utils/api';
import "./restapi.css"

type TUserInventoryRowProps = {
    userid: string
}
type TUserInventory = {
    currency: number,
    inescrow: number
}

export function UserInventoryRow(props: TUserInventoryRowProps) {
    const { userid } = props;
    const [inventory, setInventory] = useState<TUserInventory | null>(null)

    const loadData = async() => {
        return await api.tryGet<TUserInventory>(`/v2/admin/users/${userid}/inventory`)
    }

    useEffect(() => {
        loadData()
        .then(x => setInventory(x))
        .catch(err => console.log(err))

    }, [userid])

    const addCurrency = async(amount: number) => {
        const new_inventory = await api.post<TUserInventory>(`/v2/admin/users/${userid}/inventory/addcurrency`, {amount})
        setInventory(new_inventory);
    }


    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        User Inventory
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">                       
                        <div className="ml-1">
                        {inventory && <>
                            Currency: {inventory.currency?.toLocaleString()}
                            <Button className="ml-1" onClick={() => addCurrency(-10000)}>-10000</Button>
                            <Button className="ml-1" onClick={() => addCurrency(-1000)}>-1000</Button>
                            <Button className="ml-1" onClick={() => addCurrency(-100)}>-100</Button>
                            <Button className="ml-1" onClick={() => addCurrency(100)}>+100</Button>
                            <Button className="ml-1" onClick={() => addCurrency(1000)}>+1000</Button>
                            <Button className="ml-1" onClick={() => addCurrency(10000)}>+10000</Button>
                            <br/>In Escrow: {inventory.inescrow?.toLocaleString()}
                            </>}
                        </div>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)    
}

type TEscrowHoldingInfo = {
    escrowid : string,
    timestamp: string;
    type: string;
    amount : number,
    fees : number,
    senderid: string,
    receiverid: string,
    itemid: string,
    itemindex: number,
    sendername: string,
    receivername: string,
    itemname: string,
}

export function UserEscrowsRow(props: TUserInventoryRowProps) {
    const { userid } = props;
    const [escrows, setEscrows] = useState<TEscrowHoldingInfo[] | null>(null)

    const loadData = async() => {
        return await api.tryGet<TEscrowHoldingInfo[]>(`/v2/admin/users/${userid}/escrows`)
    }

    useEffect(() => {
        loadData()
        .then(x => setEscrows(x))
        .catch(err => console.log(err))

    }, [userid])

    return (
        <Row>
            <Col className="restapi-documentfields-col">
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            User Escrow Holdings
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">                       
                            <>
                                {escrows && <PageTable>
                                    <thead>
                                        <PageTableRow>
                                            <th>Timestamp</th>
                                            {/* <th>Type</th> */}
                                            <th>Amount</th>
                                            <th>Address</th>
                                            <th>Receiver</th>
                                        </PageTableRow>
                                    </thead>
                                    <tbody>
                                        {escrows.map(x => (
                                            <PageTableRow key={x.escrowid}>
                                                <PageTableCell>{x.timestamp}</PageTableCell>
                                                {/* <PageTableCell>{x.type}</PageTableCell> */}
                                                <PageTableCell>{x.amount}</PageTableCell>
                                                <PageTableCell>{x.itemname}</PageTableCell>
                                                <PageTableCell>{x.receivername}</PageTableCell>
                                            </PageTableRow>
                                        ))}
                                    </tbody>
                                </PageTable>}
                            </>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </Row>)    
    }

type TUserInventoryTransaction = {
    added: number;
    newcurrency: number;
    newinescrow:number,
    timestamp: string;
    type: string;
    userid: string;
    usernickname: string;
    usericon: string;
    id: string;
}

export function UserInventoryTransactionsRow(props: TUserInventoryRowProps) {
    const { userid } = props;
    const [transactions, setTransactions] = useState<{results:TUserInventoryTransaction[]} | null>(null)

    const loadData = async() => {
        return await api.tryGet<any>(`/v2/admin/stats/inventorytransactions?user=${userid}&propertytransactions=true&commission=true&consumables=true&rewards=true`)
    }

    useEffect(() => {
        loadData()
        .then(x => setTransactions(x))
        .catch(err => console.log(err))

    }, [userid])


    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        User Inventory Transactions
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">     
                        <>                  
                            {transactions && <PageTable>
                                <thead>
                                    <PageTableRow>
                                        <th>Timestamp</th>
                                        <th>Type</th>
                                        <th>Added</th>
                                        <th>New Currency</th>
                                        <th>New InEscrow</th>
                                    </PageTableRow>
                                </thead>
                                <tbody>
                                    {transactions.results.map(x => (
                                        <PageTableRow key={x.id}>
                                            <PageTableCell>{x.timestamp}</PageTableCell>
                                            <PageTableCell>{x.type}</PageTableCell>
                                            <PageTableCell>{x.added}</PageTableCell>
                                            <PageTableCell>{x.newcurrency}</PageTableCell>
                                            <PageTableCell>{x.newinescrow}</PageTableCell>
                                        </PageTableRow>
                                    ))}
                                </tbody>
                            </PageTable>}
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)    
}


type TInfiniverseBuilding = {
    _id: string;
    cellx: number;
    celly: number;
    subdistrictid: number;
    buildingid: number;
    name: string;
    style: any;
    area: number;
    centre?: number[];    
    doorcentre: number[];
    doordir: number[];
    centrex: number;    
    centrey: number;    
    minfloors?: number;
    maxfloors?: number;
    buildingstyle?: string;
    baseslots?: number;
    canbuy?: boolean;
    timestamp: string;
}

type TUserInfiniverseSlot = {
    _id: string;
    cellx: number;
    celly: number;
    subdistrictid: number;
    buildingid: number;
    slot: number;
    area: number;
    centre?: number[];    
    doorcentre: number[];
    doordir: number[];
    centrex: number;    
    centrey: number;    
    reservedby: string;
    reservedexpires: string;
    boughtfor: number;
    minselldate: string;
    destination: string;
    iconurl: string;
    timestamp: string;
    marketsale: number;
}

type TUserInfiniverseTransaction = {
    timestamp: string;
    type: string;
    userid: string;
    usernickname: string;
    usericon: string;
    id: string;
    amount: number;
    building: TInfiniverseBuilding;
} & TUserInfiniverseSlot

export function UserInfiniverseTransactionsRow(props: TUserInventoryRowProps) {
    const { userid } = props;
    const [transactions, setTransactions] = useState<{results:TUserInfiniverseTransaction[]} | null>(null)

    const loadData = async() => {
        const res = await api.tryGet<any>(`/v2/admin/stats/infiniversetransactions?user=${userid}`)
        console.log(res)
        return res;
    }

    useEffect(() => {
        loadData()
        .then(x => setTransactions(x))
        .catch(err => console.log(err))

    }, [userid])


    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        User Infiniverse Transactions
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">                       
                        <>
                            {transactions && <PageTable>
                                <thead>
                                    <PageTableRow>
                                        <th>Timestamp</th>
                                        <th>Type</th>
                                        <th>Amount</th>
                                        <th>Address</th>
                                        <th>Code</th>
                                        <th>Location</th>
                                    </PageTableRow>
                                </thead>
                                <tbody>
                                    {transactions.results.map(x => (
                                        <PageTableRow key={x.id}>
                                            <PageTableCell>{x.timestamp}</PageTableCell>
                                            <PageTableCell>{x.type}</PageTableCell>
                                            <PageTableCell>{x.amount}</PageTableCell>
                                            <PageTableCell>{x.slot+1} - {x.building ? x.building.name : "unknown building"}</PageTableCell>
                                            <PageTableCell>{x.cellx}_{x.celly}_{x.subdistrictid}_{x.buildingid}</PageTableCell>
                                            <PageTableCell>{x.building ? x.building.centrex.toFixed(0) : "unknown building"},{x.building ? x.building.centrey.toFixed(0) : "unknown building"}</PageTableCell>
                                        </PageTableRow>
                                    ))}
                                </tbody>
                            </PageTable>}
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)    
}

type TInfiniverseSlotsRowProps = {
    title?: string;
    onvisit?: (slot: TUserInfiniverseSlotsResult)=>void;
    slots: TUserInfiniverseSlotsResult[]
}
export function InfiniverseSlotsRow(props: TInfiniverseSlotsRowProps) {
    const { slots } = props;


    const calcPostCode = (slot: TUserInfiniverseSlotsResult) => {
        let x = slot.cellx-2147483647;
        let y = slot.celly-2147483647;
        const xprefix = x < 0 ? 'W' : 'E'
        const yprefix = y < 0 ? 'S' : 'N'
        x = Math.abs(x)
        y = Math.abs(y)
        return `${yprefix}${y}-${xprefix}${x}-${slot.subdistrictid}-${slot.buildingid}`
    }

    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        {props.title || "User Infiniverse Slots"}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0" className="restapi-documents-col">                       
                        <>
                            {slots && <PageTable>
                                <thead>
                                    <PageTableRow>
                                        {props.onvisit && <th></th>}
                                        <th style={{width:200}}>Address</th>
                                        <th style={{width:90}}>Code</th>
                                        <th style={{width:90}}>Coord</th>
                                        <th style={{width:60}}>Bought For</th>
                                        <th style={{width:70}}>On Sale</th>
                                        <th>Destination</th>
                                    </PageTableRow>
                                </thead>
                                <tbody>
                                    {slots.map(x => (
                                        <PageTableRow key={x._id}>
                                            {props.onvisit && <PageTableCell><Button variant="primary" onClick={() => { props.onvisit && props.onvisit(x) }}/></PageTableCell>}
                                            <PageTableCell>{x.slot < 65538 ? `${x.slot+1} - ${x.building.name}` : x.building.name}</PageTableCell>
                                            <PageTableCell>{calcPostCode(x)}</PageTableCell>
                                            <PageTableCell>{x.building.centrex.toFixed(0)},{x.building.centrey.toFixed(0)}</PageTableCell>
                                            <PageTableCell>{x.boughtfor}</PageTableCell>
                                            <PageTableCell>{x.marketsale}</PageTableCell>
                                            <PageTableCell>{x.destination}</PageTableCell>
                                        </PageTableRow>
                                    ))}
                                </tbody>
                            </PageTable>}
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)    
}


type TUserInfiniverseSlotsRowProps = {
    userid: string,
    uri?: string,
    title?: string
}
type TUserInfiniverseSlotsResult = {
    building: TInfiniverseBuilding;
} & TUserInfiniverseSlot

export function UserInfiniverseSlotsRow(props: TUserInfiniverseSlotsRowProps) {
    const { userid } = props;
    const [slots, setSlots] = useState<TUserInfiniverseSlotsResult[] | null>(null)

    const loadData = async() => {
        const res = await api.tryGet<any>(props.uri || `/v2/admin/users/${userid}/infiniverse/slots`)
        console.log(res)
        return res;
    }

    useEffect(() => {
        loadData()
        .then(x => setSlots(x))
        .catch(err => console.log(err))

    }, [userid])

    const calcPostCode = (slot: TUserInfiniverseSlotsResult) => {
        let x = slot.cellx-2147483647;
        let y = slot.celly-2147483647;
        const xprefix = x < 0 ? 'W' : 'E'
        const yprefix = y < 0 ? 'S' : 'N'
        x = Math.abs(x)
        y = Math.abs(y)
        return `${yprefix}${y}-${xprefix}${x}-${slot.subdistrictid}-${slot.buildingid}`
    }

    return (
        <>
        {slots && <InfiniverseSlotsRow title={props.title} slots={slots}/>}
        </>
    )    
}



type TUserUnconsumedIAPResult = {
    id: string,
    grant_time: number,
    expiration_time: number,
    item: {
        sku: string,
        id: string
    }
}

export function UserUnconsumedIAPRow(props: {userid: string}) {
    const [iaps, setIaps] = useState<TUserUnconsumedIAPResult[] | null>(null)

    const loadData = async() => {
        const res = await api.tryGet<any>(`/v2/admin/users/${props.userid}/unconsumediaps`)
        console.log(res)
        return res;
    }

    useEffect(() => {
        loadData()
        .then(x => setIaps(x))
        .catch(err => console.log(err))

    }, [props.userid])


    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        User Unconsumed IAPS
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">                       
                        <>
                            {iaps && <PageTable>
                                <thead>
                                    <PageTableRow>
                                        <th>Grant Time</th>
                                        <th>Sku</th>
                                    </PageTableRow>
                                </thead>
                                <tbody>
                                    {iaps.map(x => (
                                        <PageTableRow key={x.grant_time}>
                                            <PageTableCell>{new Date(x.grant_time*1000).toLocaleString()}</PageTableCell>
                                            <PageTableCell>{x.item.sku}</PageTableCell>
                                        </PageTableRow>
                                    ))}
                                </tbody>
                            </PageTable>}
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)    
}
