import React, { ChangeEventHandler, ReactNode, useState } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Image, Card, Button, Spinner } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { Agent, AgentChildComponentProps } from '../../pages/agentHomePage';


type AgentDetailsCardProps = {
    
} & AgentChildComponentProps

export function _AgentDetailsCard(props: AgentDetailsCardProps) {

    return ( 
        <Card className="mt-2 discovery-content-card">
            <Card.Body>
                <p>{props.agent?.personality}</p>
                <p>{props.agent?.speech_style}</p>
            </Card.Body>
        </Card>
    )
}
export const AgentDetailsCard =_AgentDetailsCard;

export default AgentDetailsCard;
