
import React , {useEffect, useRef, useState} from 'react';
import { MultiverseFile, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Accordion, Breadcrumb, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';



// stats summary of number of agents existing, number of user memories, etc, etc
// uses agent stats api endpoint


export function AgentsAdminOverviewCard(props: WithMultiverseApiProps) {
    // holds key/value pairs of stats
    const [stats, setStats] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // returns key/value pairs of stats

        props.multiverse.get<any>({url: '/v2/admin/agents/stats'})
            .then((response) => {
                console.log(response);
                setStats(response);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    return (
        <Card>
            <Card.Header>Stats</Card.Header>
            <Card.Body>
                {loading ? <Spinner animation="border" /> : (
                    <ListGroup>
                        {Object.keys(stats).map((key) => (
                            <ListGroupItem key={key}>
                                <b>{key}</b>: {stats[key]}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                )}
            </Card.Body>
        </Card>
    );
}



