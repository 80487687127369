import React , {useEffect, useRef, useState} from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseFile, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { Accordion, Breadcrumb, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

type AdminAgentsPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type TGlobalConfig = {
  agent_creation_cost_metacoins?: number,
  agent_recraft_cost_metacoins?: number,
  agent_recraft_faceicon_cost_metacoins?: number,
}


const GlobalConfigEditorCard = ({
  globalConfig,
  editedGlobalConfig,
  setEditedGlobalConfig,
  onSave
}: {
  globalConfig: TGlobalConfig | undefined;
  editedGlobalConfig: TGlobalConfig | undefined;
  setEditedGlobalConfig: (config: TGlobalConfig) => void;
  onSave: () => void;
}) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          Global Config
          <Button
            className="ml-3"
            disabled={JSON.stringify(globalConfig) === JSON.stringify(editedGlobalConfig)}
            onClick={onSave}
          >
            Send to Server
          </Button>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group as={Row} controlId="creation_cost" className="align-items-center">
            <Form.Label>Agent Creation Cost (Metacoins)</Form.Label>
            <Form.Control
              type="number"
              value={editedGlobalConfig?.agent_creation_cost_metacoins || 0}
              onChange={(e) =>
                setEditedGlobalConfig({
                  ...editedGlobalConfig!,
                  agent_creation_cost_metacoins: parseInt(e.target.value)
                })
              }
            />
          </Form.Group>
          <Form.Group as={Row} controlId="recraft_cost" className="align-items-center">
            <Form.Label>Agent Recraft Cost (Metacoins)</Form.Label>
            <Form.Control
              type="number"
              value={editedGlobalConfig?.agent_recraft_cost_metacoins || 0}
              onChange={(e) =>
                setEditedGlobalConfig({
                  ...editedGlobalConfig!,
                  agent_recraft_cost_metacoins: parseInt(e.target.value)
                })
              }
            />
          </Form.Group>
          <Form.Group as={Row} controlId="recraft_face__cost" className="align-items-center">
            <Form.Label>Agent Recraft Faceicon Cost (Metacoins)</Form.Label>
            <Form.Control
              type="number"
              value={editedGlobalConfig?.agent_recraft_faceicon_cost_metacoins || 0}
              onChange={(e) =>
                setEditedGlobalConfig({
                  ...editedGlobalConfig!,
                  agent_recraft_faceicon_cost_metacoins: parseInt(e.target.value)
                })
              }
            />
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};


function _AdminAgentsPage( props: AdminAgentsPageProps) {

  const [globalConfig, setGlobalConfig] = useState<TGlobalConfig>();
  const [loading, setLoading] = useState<boolean>(true);

  const [editedGlobalConfig, setEditedGlobalConfig] = useState<TGlobalConfig>();


  useEffect(() => {
    props.multiverse.get<TGlobalConfig>("/v2/agents/globalconfig_costs").then((config) => {
      setGlobalConfig(config);
      setEditedGlobalConfig(config);
      setLoading(false);
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    })
  }, []);



  return (
    <AdminPage>
      <h4><b><Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/agents" }}>Agents</Breadcrumb.Item>
          <Breadcrumb.Item active><b>Edit Configs</b></Breadcrumb.Item>
      </Breadcrumb></b></h4>

      {!loading && (
        <GlobalConfigEditorCard
        globalConfig={globalConfig}
        editedGlobalConfig={editedGlobalConfig}
        setEditedGlobalConfig={(cfg) => setEditedGlobalConfig(cfg)}
        onSave={() => {
          props.multiverse.post<TGlobalConfig>("/v2/admin/agents/globalconfig", editedGlobalConfig).then((config) => {
            setGlobalConfig(config);
          }).catch((err) => {
            console.error(err);
            alert(`Error saving config: ${err}`);
          });
        }}
        />
      )}

    </AdminPage>
  );
}
export const AdminAgentsConfigsPage = withRouter(withMultiverseApi(_AdminAgentsPage));

export default AdminAgentsConfigsPage;

