import React , {useEffect, useRef, useState} from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseFile, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { Accordion, Breadcrumb, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import PageTable from '../../components/pageTable';

type AdminAgentsPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type TAgentTokens = {
  total_tokens: number,
  completion_tokens: number,
  prompt_tokens: number,
}
type UserMemory = {
  _id: string;
  type: string,
  entity_id: {
    _id: string,
    nickname: string,
    iconblob: string
  }

  hard_facts: {
    last_updated: Date
    name?: string;
    ai_summary?: string;
    metaverse_ownership?: string[];
    metaverse_locations?: {
        [metaverse: string]: string[];  // Metaverse ID/Name -> Array of locations
    };
    [key: string]: any; // extendable for more known facts

};
interactions?: {
    [agent_id: string]: {
        last_updated: Date
        ai_summary?: string;
        extracted_facts?: {
            [key: string]: string;
        };
    };
};

tokens: TAgentTokens;
};

type UserMemoryRow = {
  _id: string;
  type: string,
  entity_id: {
    _id: string,
    nickname: string,
    iconblob: string
  },
  updatedAt: string
}


function _AdminAgentsPage( props: AdminAgentsPageProps) {


  const [memoriesList, setMemoriesList] = useState<UserMemoryRow[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    props.multiverse.get<UserMemoryRow[]>("/v2/admin/agents/memories?sort=updatedAt&order=-1").then((users) => {
      setMemoriesList(users);
      setLoading(false);
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    })
  }, []);

  function onRowClick(m: UserMemoryRow) {
    props.history.push(`/admin/agents/memories/${m._id}`);
  }



  return (
    <AdminPage>
      <h4><b><Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/agents" }}>Agents</Breadcrumb.Item>
          <Breadcrumb.Item active><b>View User Memories</b></Breadcrumb.Item>
      </Breadcrumb></b></h4>

      <>
      <Row className="p-0">
          <Col>
          <PageTable className="m-0"><tbody>
            {memoriesList.map((m) => {
                return (
                    <PageTable.Row key={m._id} onClick={() => onRowClick(m)} style={{ cursor: "pointer"}} >
                        <PageTable.IconCell className="dp-icon-cell" src={m.entity_id.iconblob!} roundedCircle style={{ border: "none" }}/> 
                        <PageTable.InfoCell className="dp-name-cell" title={m.entity_id.nickname} subtitle={m.type} style={{ border: "none", justifyContent: "flex-end" }}/>
                        <PageTable.InfoCell className="dp-date-cell" title={m.updatedAt} style={{ border: "none", justifyContent: "flex-end" }}/>
                        {/* <PageTable.Cell style={{ border: "none", textAlign: "right", paddingRight: "0.5rem", alignItems: "center"}}>
                            <div>
                                {x.platforms?.map((platform) => (
                                <img
                                    key={platform}
                                    src={`agentplatform_${platform}.jpg`}
                                    alt={platform}
                                    style={{ width: "20px", height: "20px", borderRadius: "4px", objectFit: "cover" }}
                                />
                                ))}
                            </div>
                        </PageTable.Cell> */}
                        <PageTable.Cell style={{ fontFamily: "monospace", fontSize: "1.25rem", textAlign: "right", width:"20px", border: "none" }}>{'>'}</PageTable.Cell>

                    </PageTable.Row>
                )
            })
        }
          </tbody></PageTable>
          </Col>
      </Row>
      </>

    </AdminPage>
  );
}
export const AdminAgentsViewMemoriesPage = withRouter(withMultiverseApi(_AdminAgentsPage));

export default AdminAgentsViewMemoriesPage;

