import React , {useEffect, useRef, useState} from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseFile, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { Accordion, Breadcrumb, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import PageTable from '../../components/pageTable';


type AdminAgentsPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type TAgentSummary = {
  agent_id: string;
  display_name: string;
  display_tagline?:string;
  faceicon_url?:string;
  platforms?: string[];
  owner_id?:string;
  owner_name?:string;
  owner_icon_url?:string;
  timestamp: string;
}

function _AdminAgentsPage( props: AdminAgentsPageProps) {

  const [agentsList, setAgentsList] = useState<TAgentSummary[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    props.multiverse.get<TAgentSummary[]>("/v2/admin/agents/agents?sort=display_name&order=1").then((agents) => {
      setAgentsList(agents);
      setLoading(false);
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    })
  }, []);

  function onRowClick(m: TAgentSummary) {
    props.history.push(`/admin/agents/view/${m.agent_id}`);
  }




  const renderTableContainerRow = (agents: TAgentSummary[]) => {        
    return (
        agents.map((x) => {
            return (
                <PageTable.Row key={x.agent_id} onClick={() => onRowClick(x)} style={{ cursor: "pointer"}} >
                    <PageTable.IconCell className="dp-icon-cell" src={x.faceicon_url!} roundedCircle style={{ border: "none" }}/> 
                    <PageTable.InfoCell className="dp-name-cell" title={x.display_name} subtitle={x.display_tagline} style={{ border: "none", justifyContent: "flex-end" }}/>
                    <PageTable.InfoCell className="dp-date-cell" title={x.timestamp} style={{ border: "none", justifyContent: "flex-end" }}/>
                    <PageTable.Cell style={{ border: "none", textAlign: "right", paddingRight: "0.5rem", alignItems: "center"}}>
                        <div>
                            {x.platforms?.map((platform) => (
                            <img
                                key={platform}
                                src={`agentplatform_${platform}.jpg`}
                                alt={platform}
                                style={{ width: "20px", height: "20px", borderRadius: "4px", objectFit: "cover" }}
                            />
                            ))}
                        </div>
                    </PageTable.Cell>
                    {x.owner_icon_url && <PageTable.IconCell className="dp-icon-cell" src={x.owner_icon_url!} roundedCircle style={{ border: "none" }}/>}
                    {x.owner_id && <PageTable.InfoCell className="dp-name-cell" title={x.owner_name} subtitle={x.owner_id} style={{ border: "none", justifyContent: "flex-end" }}/>}
                    <PageTable.Cell style={{ fontFamily: "monospace", fontSize: "1.25rem", textAlign: "right", width:"20px", border: "none" }}>{'>'}</PageTable.Cell>
                </PageTable.Row>
            )
        })
    )
}


  return (
    <AdminPage>
      <h4><b><Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/agents" }}>Agents</Breadcrumb.Item>
          <Breadcrumb.Item active><b>View Agents</b></Breadcrumb.Item>
      </Breadcrumb></b></h4>

      <Row className="p-0">
          <Col>
          <PageTable className="m-0"><tbody>
            {renderTableContainerRow(agentsList)}
          </tbody></PageTable>
          </Col>
      </Row>

    </AdminPage>
  );
}
export const AdminAgentsViewPage = withRouter(withMultiverseApi(_AdminAgentsPage));

export default AdminAgentsViewPage;

