import React, { useState } from 'react';
import BasicPage from '../../components/basicPage';
import AgentsTable from './agentstable';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
//import "./domainspage.css"

const TextCellStyle = {
    verticalAlign: "middle"
}
const ButtonCellStyle = {
    width: 50,
    verticalAlign: "middle"
}

type TAgentsPageProps = {
} & WithMultiverseApiProps;

export function _AgentsPage(props: TAgentsPageProps) {

    const [isLoading, setLoading] = useState<boolean>(false)

    return (
        <>
            <BasicPage className="domains-page" isLoading={isLoading} title="Your AI Agents" >
                <AgentsTable group/>
            </BasicPage>
        </>);
}

export const AgentsPage = withMultiverseApi(_AgentsPage);

export default AgentsPage;
