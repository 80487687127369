import React, { ChangeEventHandler, ReactNode, useEffect, useState } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Image, Card, Button, Spinner } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { Agent, AgentChildComponentProps } from '../../pages/agentHomePage';
import { Link } from 'react-router-dom';


type AgentPlatformsCardProps = {

} & AgentChildComponentProps

type AgentUsage = {
    platform: string;
    summary: string;
    usages: string[];
}


export function _AgentPlatformsCard(props: AgentPlatformsCardProps) {

    // get agent usages
    const [agentUsages, setAgentUsages] = useState<any[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const loadAgentUsages = async() => {
            // get agent info for confirmation before showing dialog
            try {
                const usages = await props.multiverse.get<AgentUsage[]>(`/v2/agents/usages/${props.agent_id}`);
                setAgentUsages(usages)
            } catch (e) {
                console.error(e)
            }
            setLoading(false)
        }
        loadAgentUsages()
    }
    , [props.shouldReload])

    return ( 
        <Card className="mt-2 discovery-content-card">
            <Card.Header>Agent Platforms</Card.Header>
            <Card.Body>
                {/* // show list of platforms, and usages within those platforms */}
                {loading && <Spinner animation="border" />}
                {!loading && agentUsages.map((usage, index) => {
                    const has_locations = usage.usages.length > 0;
                    return (
                        <Card bg='light' key={index}>
                        <Card.Header>{usage.platform}</Card.Header>
                        <Card.Body>
                            {has_locations && <>
                            <p>Your agent is used in these locations in {usage.platform}:</p>
                            <p>{usage.usages.map((x:string) => {return <li><Link to={`/domains/${x}`}>{x}</Link></li>}) }</p></>}
                            {!has_locations &&
                            <p>Navigate to your metaverses and locations to use this agent in {usage.platform}!</p>}
                        </Card.Body>
                        </Card>
                    )
                }
            )}

            </Card.Body>
        </Card>
    )
}
export const AgentPlatformsCard =_AgentPlatformsCard;

export default AgentPlatformsCard;
