import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Button, Card, Image } from 'react-bootstrap';
import OculusLogo from '../../assets/oculus-logo-transparent-white.png';

type LaunchCardProps = {
    domainName?: string;
    domainUri?: string;
    locationName?: string;
    inviteSecret?: string;

} & WithMultiverseApiProps;

type LaunchCardState = {
};

class _LaunchCard extends React.Component<LaunchCardProps, LaunchCardState> {
    constructor(props: LaunchCardProps) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount(): Promise<void> {

    }

    onConnectButtonClicked = async () => {
        const { domainUri, domainName, inviteSecret, locationName, multiverse: { requestOculusLaunch, get } } = this.props;
        if (!domainUri || !domainName) {
            requestOculusLaunch({
                title: `Launch Multiverse`,
                domain: "",
                location: "",
                inviteSecret: inviteSecret,
                prompt: "confirm"
            })
        } else {
            requestOculusLaunch({
                title: `Launch ${domainName}`,
                domain: domainUri,
                location: locationName,
                inviteSecret: inviteSecret,
                prompt: "confirm"
            })
        }
    }

    onLaunchDesktopButtonClicked = async () => {
        const { domainUri, domainName, inviteSecret, locationName, multiverse: { requestOculusLaunch, get } } = this.props;
        if (!domainUri || !domainName) {
            requestOculusLaunch({
                title: `Launch Multiverse`,
                domain: "",
                location: "",
                inviteSecret: inviteSecret,
                prompt: "confirm",
                nonvr: true
            })
        } else {
            requestOculusLaunch({
                title: `Launch ${domainName}`,
                domain: domainUri,
                location: locationName,
                inviteSecret: inviteSecret,
                prompt: "confirm",
                nonvr: true
            })
        }
    }

    // onLaunchDesktopButtonClicked = async () => {
    //     const { domainUri, domainName, locationName } = this.props;
    //     if (!domainUri || !domainName) {
    //         return;
    //     }

    //     // open url in new window to launch in desktop
    //     let url = `multiverse://destination?url=${domainUri}`;
    //     if(locationName)
    //         url += `/locations/${locationName}`;
    //     window.open(url, '_blank');
    // }

    render(): JSX.Element {
        return ( 
            <Card className="mt-2 ml-2 launch-row">
                <Card.Body>
                    <Button variant="primary" className="launch-button" onClick={this.onConnectButtonClicked}>
                        <Image src={OculusLogo} />
                        <p>Launch In VR</p>
                    </Button>
                    {" "}
                    <Button variant="primary" className="launch-button" onClick={this.onLaunchDesktopButtonClicked}>
                        <Image src={OculusLogo} />
                        <p>Launch on Desktop</p>
                    </Button>
                </Card.Body>
            </Card>
        )
    }
}
export const LaunchCard = withMultiverseApi(_LaunchCard);

export default LaunchCard;
