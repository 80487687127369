import React, { CSSProperties, FunctionComponent } from 'react';
import { Button, Col, FormControl, FormControlProps, Modal, Image, Spinner } from 'react-bootstrap';
import { Subtract } from 'utility-types';
import { MultiverseLaunchRequest, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import LaunchOculusImage from '../../assets/deeplink.jpg';
import LaunchOculusNonVRImage from '../../assets/deeplinknonvr.png';
import { Link } from 'react-router-dom';

type LaunchOculusDialogBoxProps = {

} & WithMultiverseApiProps;

type LaunchOculusDialogBoxState = {
    currentLaunchRequest?: MultiverseLaunchRequest,
    isFetching: boolean;
    isLaunching: boolean;
    linkId?: string;
    linkUrl?: string;
    timeout?: number;
};

const NORMAL_HEADER_STYLE = {

}

const LOADING_DIV_STYLE: CSSProperties = {
    margin: 0,
    position: "absolute",
    top: "50%",
    transform: "translate(0,-50%)",
    textAlign: "center"
}

const DEEPLINK_IMAGE_DIV_STYLE: CSSProperties = {
    margin: "auto",
    width: "50%"
}
const DEEPLINK_IMAGE_STYLE: CSSProperties = {
    maxWidth: "100%"
}

//checks if 2 launch requests (which may be undefined) are equal
function matchLaunchRequests(a?: MultiverseLaunchRequest, b?: MultiverseLaunchRequest) {
    return a?.location == b?.location && a?.domain == b?.domain && a?.inviteSecret == b?.inviteSecret;
}

//dialog box shown whilst attempting to connect to oculus
class LaunchOculusDialogBox extends React.Component<LaunchOculusDialogBoxProps, LaunchOculusDialogBoxState> {
    constructor(props: LaunchOculusDialogBoxProps) {
        super(props);
        this.state = {
            currentLaunchRequest: undefined,
            isFetching: false,
            isLaunching: false,
            linkId: undefined,
            linkUrl: undefined
        };
    }

    componentDidMount = () => {
        this.refreshLaunchRequest();
    }

    componentWillUnmount = () => {
        const { timeout } = this.state;
        if (timeout) {
            window.clearTimeout(timeout);
        }
    }

    componentDidUpdate = () => {
        const { currentLaunchRequest, isFetching } = this.state;
        const { launchRequest } = this.props;

        if (!matchLaunchRequests(launchRequest, currentLaunchRequest)) {
            this.refreshLaunchRequest();
        }
    }

    refreshLaunchRequest = async () => {
        const { currentLaunchRequest, isFetching } = this.state;
        const { launchRequest, multiverse: { get, isAuthenticated } } = this.props;
        console.log(launchRequest);
        console.log(currentLaunchRequest);
        if (!launchRequest) {
            this.setState({
                currentLaunchRequest: undefined
            });
        } else {
            this.setState({
                isFetching: true,
                currentLaunchRequest: { ...launchRequest }
            });

            try {

                const secret = launchRequest.inviteSecret || "";
                const domain = launchRequest.domain || "";
                const location = launchRequest.location || "";
                const nonvr = launchRequest.nonvr || false;

                let res: any;
                if(secret != "") {
                    res = await get<any>(`/v2/public/oculusdeeplink?domain=${domain}&secret=${secret}&location=${location}&nonvr=${nonvr}`)
                } else if (domain != "") {
                    if(!isAuthenticated) {
                        if (location != "") {
                            res = await get<any>(`/v2/public/domains/${launchRequest.domain}/locations/${launchRequest.location}/oculusdeeplink?nonvr=${nonvr}`)
                        } else {
                            res = await get<any>(`/v2/public/domains/${launchRequest.domain}/oculusdeeplink?nonvr=${nonvr}`)
                        }
                    } else {
                        if (location != "") {
                            res = await get<any>(`/v2/domains/${launchRequest.domain}/locations/${launchRequest.location}/oculusdeeplink?nonvr=${nonvr}`)
                        } else {
                            res = await get<any>(`/v2/domains/${launchRequest.domain}/oculusdeeplink?nonvr=${nonvr}`)
                        }
                    }

                } else {
                    if (location != "") {
                        throw new Error("Can not request oculus launch with location but no domain");
                    }

                    if(isAuthenticated) {
                        res = await get<any>(`/v2/oculusdeeplink?nonvr=${nonvr}`)
                    } else {
                        res = await get<any>(`/v2/public/oculusdeeplink?nonvr=${nonvr}`)
                    }
                }
                console.log(res);

                if(launchRequest.prompt === "confirm") {
                    this.setState({
                        linkUrl: res.url,
                        linkId: res.msgid
                    })
                } else {
                    document.location.href = res.url;
                }

            } catch (err) {
                console.log(err);
            }

            if(launchRequest.prompt === "confirm") {
                this.setState({
                    isFetching: false
                });
            }
        }
    }

    renderImage = () => {
        return (
            <div style={DEEPLINK_IMAGE_DIV_STYLE}>
                <Image src={this.state.currentLaunchRequest?.nonvr?LaunchOculusNonVRImage:LaunchOculusImage} style={DEEPLINK_IMAGE_STYLE}/>
            </div>
        )
    }


    launchClicked = () => {
        if (this.state.linkUrl) {
            //open a new tab to do the fancy redirect trick
            const tab = window.open();
            if (tab) {
                if(this.state.currentLaunchRequest?.nonvr)
                    tab.document.write('Please wait while your Multiverse app launches');
                else
                    tab.document.write('Please wait while you are redirected');
                tab.location.href = this.state.linkUrl;

                this.setState({
                    isLaunching: true
                })

                this.startNextPoll();
            }
        }
    }

    startNextPoll = () => {
        console.log("Starting next timeout")
        this.setState({
            timeout: window.setTimeout(() => this.poll(), 1500)
        })
    }

    //polls for deep link completion
    poll = async () => {
        const { timeout, linkId } = this.state;
        const { multiverse: { user, get, cancelOculusLaunch, reauthenticate, updateUser } } = this.props;

        //clear time out that triggered this poll
        if (timeout) {
            window.clearTimeout(timeout);
        }

        //if link id has gone invalid since last poll, stop here
        if(!linkId) {
            return;
        }

        //checking for deep link depends on whether this is an unauthenticated, account linking, or normal launch
        if(!user || user.oculusaccount) {
            //if no user (not authenticated) or already have an oculus account (normal launch), just poll the public status end point
            const { used } = await get<{ used: boolean }>({
                url: `/v2/public/oculusdeeplink/${linkId}/status`,
                hideErrors: true
            });
            if (used) {
                cancelOculusLaunch();
                this.setState({
                    currentLaunchRequest: undefined,
                    isFetching: false,
                    isLaunching: false,
                    linkId: undefined,
                    linkUrl: undefined
                });
                return;
            }
        } else {
            //if user doesn't have an account, we just wait for the account update to fail, indicating merge is complete
            try {
                await updateUser();
            } catch (e) {
                console.log(`updateUser exception ${e} fired, indicating user merge has finished`)
                await reauthenticate();
                return;
            }
        }

        //not finished so trigger next poll
        this.startNextPoll();
    }

    cancelClicked = () => {
        const { multiverse: { cancelOculusLaunch } } = this.props;
        const { timeout } = this.state;
        if (timeout) {
            window.clearTimeout(timeout);
        }
        cancelOculusLaunch();
        this.setState({
            currentLaunchRequest: undefined,
            isFetching: false,
            isLaunching: false,
            linkId: undefined,
            linkUrl: undefined,
            timeout: undefined
        });
    }

    renderMessage() {
        const { multiverse: { user } } = this.props;
        const { isFetching, isLaunching, currentLaunchRequest } = this.state;
        const busy = isFetching || isLaunching;
        const platform = currentLaunchRequest?.nonvr ? "on Desktop!" : "in VR!";

        if(!user) {
            return <div style={{ visibility: (busy ? "hidden" : "unset") }}>
            <p className="mb-1">Click <strong>Launch</strong> to open Multiverse {platform}</p>
            {this.renderImage()}
        </div>
        } else if(!user.oculusaccount) {
            return (<div style={{ visibility: (busy ? "hidden" : "unset") }}>
            <p className="mb-1">Click <strong>Launch</strong> to link your Oculus account, and open Multiverse!</p>
            {this.renderImage()}
            <p className="mb-1 mt-1"><strong>Please note:</strong> Account linking is a permanent decision.</p>
        </div>)
        } else {
            return (<div style={{ visibility: (busy ? "hidden" : "unset") }}>
            <p className="mb-1">Click <strong>Launch</strong> to open Multiverse {platform}</p>
            {this.renderImage()}
            <p className="mb-1 mt-1">Please ensure you are signed in with your linked Oculus account, <strong>{user?.name}</strong>.
        </p>
        </div>)
        }
    }

    render() {
        const { multiverse: { user } } = this.props;
        const { currentLaunchRequest, isFetching, isLaunching } = this.state;
        const busy = isFetching || isLaunching;
        const prompt = currentLaunchRequest?.prompt || 'none';
        return (
            <>
                {currentLaunchRequest && prompt !== 'none' && <Modal show>
                    <Modal.Header style={NORMAL_HEADER_STYLE}>
                        <Modal.Title>{currentLaunchRequest.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {busy && <Col className="col-12 p-0" style={LOADING_DIV_STYLE}>
                            <Spinner animation="border" role="status" />
                            <p className="mt-3 mb-0">
                                {isFetching ? "Contacting Oculus" : "Waiting For Connection"}
                            </p>
                        </Col>}
                        {this.renderMessage()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" disabled={busy} onClick={this.launchClicked}>Launch!</Button>
                        <Button variant="secondary" onClick={this.cancelClicked}>Cancel</Button>

                    </Modal.Footer>
                </Modal>}
            </>
        )
    }

}

export default withMultiverseApi(LaunchOculusDialogBox);
