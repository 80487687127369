import React from 'react';
import ReactMarkdown from 'react-markdown';


interface WrappedMarkdownProps {
  children: string;
}

export const MarkdownWrappedCode: React.FC<WrappedMarkdownProps> = ({ children }) => {
  return (
    <ReactMarkdown components={{
      pre: ({ node, ...props }) => (
        <pre
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          background: '#f8f9fa',
          padding: '0.5rem',
          borderRadius: '0.25rem',
        }}
        {...props}
        />
      ),
      code: ({ node, ...props }) => (
        <code
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
        {...props}
        />
      ),
    }}
    >
    {"```\n"+children}
    </ReactMarkdown>
  );
};

