import React, { ChangeEventHandler, ReactNode } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Image, Card, Button, Spinner } from 'react-bootstrap';
import CraftBanner from '../../assets/CraftBanner.jpg';
import { IoMdCamera } from 'react-icons/io'
import { RiPencilFill } from 'react-icons/ri'
//import './headercard.css'
import ValidatedField, { VALID_DOMAIN_NAME } from '../validatedField';
import IsDeveloper from '../isDeveloper';
import { Agent } from '../../pages/agentHomePage';

type AgentHeaderCardProps = {
    agent: Agent | undefined
} & WithMultiverseApiProps;

type AgentHeaderCardState = {
    editingTitle: boolean;
    editingTitleValue: string;
    savingIcon: boolean;
    savingBanner: boolean;
    savingTitle: boolean;
};

const IMAGE_TYPES = ".jpg,.jpeg,.png"

class _AgentHeaderCard extends React.Component<AgentHeaderCardProps, AgentHeaderCardState> {
    iconInputRef = React.createRef<HTMLInputElement>();
    bannerInputRef = React.createRef<HTMLInputElement>();
    titleInputRef = React.createRef<ValidatedField>();


    constructor(props: AgentHeaderCardProps) {
        super(props);
        this.state = {
            editingTitle: false,
            editingTitleValue: "",
            savingIcon: false,
            savingBanner: false,
            savingTitle: false
        };
    }

    async componentDidMount(): Promise<void> {

    }

    displayTitle = () => {
        return (
            <>
                <h3>{this.props.agent? this.props.agent.display_name : "Create New Agent"}</h3>
            </>
        )
    }
    displayTagline = () => {
        return (
            <>
                <h6>{this.props.agent?.display_tagline}</h6>
            </>
        )
    }

    render(): JSX.Element {
        const classes = ["hc-header"];

        return ( 
            <Card className={classes.join(" ")}>
                {this.props.agent && <Card.Body className="hc-bannerbody">
                    <div className="hc-bannerplaceholder"/>
                    <Image className="hc-banner" src={CraftBanner}/>
                </Card.Body>}

                {this.props.agent && <Card.Body className="hc-iconbody">
                    <div className="hc-iconholder" style={{ width: "300px", height: "300px", transform: "translate(0%, -94%)" }}>
                        <Image className="hc-icon" src={this.props.agent.faceicon_url}/>
                    </div>
                </Card.Body>}
                
                <Card.Body style={{ position: "relative" }} className="hc-body">
                    {!this.state.editingTitle && this.displayTitle()}
                    <div>{this.displayTagline()}</div>
                    {this.props.children && <div>{this.props.children}</div>}
                </Card.Body>


            </Card>
        )
    }
}
export const AgentHeaderCard = withMultiverseApi(_AgentHeaderCard);

export default AgentHeaderCard;
