import React, { useState, useEffect } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps, MultiverseBaseEntity, MultiverseDomain, MultiverseLocation, MultiverseRoom, MultiverseEvent, MultiverseUser } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import api from '../../utils/api';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { AgentHeaderCard } from '../../components/agents/agentHeaderCard';
import eventBus from '../../utils/eventBus';
import IsAuthenticated from '../../components/isAuthenticated';
//import './AgentPage.css'
import { isProd } from '../../config/api';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import AgentCraftingCard from '../../components/agents/agentCraftingCard';
import AgentDeveloperCard from '../../components/agents/agentDevCard';
import AgentPlatformsCard from '../../components/agents/agentPlatformsCard';
import AgentDetailsCard from '../../components/agents/agentDetailsCard';
import IsDeveloper from '../../components/isDeveloper';
import { IsAgentOwner, IsNotAgentOwner } from '../../components/agents/agentIsOwner';
import AgentChatCard from '../../components/agents/agentChatCard';
import AgentDeleteCard from '../../components/agents/agentDeleteCard';

// publically displayed agent data
export interface Agent {
    _id: string;
    display_name: string;
    display_tagline?: string;
    faceicon_url?: string;
    personality?: string
    speech_style?: string
    platforms?: string[],
    owner_id: string
}

// raw agent data object
export interface AgentRaw extends Record<string, any> {}
    
type AgentView = 'home' | 'crafting' | 'platforms' | 'chat' | 'delete' |'dev' | undefined

type AgentComponentProps = {
    agent_id: string
    view: AgentView
} & WithMultiverseApiProps & RouteComponentProps;

export type AgentChildComponentProps = {
    agent_id: string
    agent? : Agent,
    agentRaw? : AgentRaw,
    shouldReload: number,
    reload: () => void
} & WithMultiverseApiProps & RouteComponentProps;

export function AgentComponent(props: AgentComponentProps) {
    const [shouldReload,setShouldReload] = useState(0)
    // callback for child components to trigger a reload
    const reload = () => {
        setShouldReload(prev => prev + 1); // use a counter rather than boolean to force a reload without having to reset the boolean!
    }

    const [agent, setAgent] = useState<Agent | undefined>(undefined)
    const [agentRaw, setAgentRaw] = useState<AgentRaw | undefined>(undefined)


    const loadData = async() => {
        const res = await api.tryGet<any>(`/v2/agents/${props.agent_id}`)
        console.log(res)
        return res;
    }

    useEffect(() => {
        if(props.agent_id) {
            loadData()
            .then(x => {
                setAgent(x)
                setAgentRaw(x)
            })
            .catch(err => console.log(err))
        }
    }, [shouldReload])
    
    const [requireContent,setRequireContext] = useState<boolean>(false)

    return <div>
    {/* </div>return <div>  style={{display:"flex", flexDirection:"column", height:"100%"}}> */}
    {<AgentHeaderCard agent={agent}/>}
        {agent && (props.view === 'crafting' || props.view === 'home') && <AgentDetailsCard agent={agent} shouldReload={shouldReload} reload={reload} {...props}/>}
        {agent && agent.owner_id === props.multiverse.user?.id && <>
            {props.view === 'crafting' && <AgentCraftingCard agent={agent} shouldReload={shouldReload} reload={reload} {...props}/>}
            {props.view === 'platforms' && <AgentPlatformsCard agent={agent} shouldReload={shouldReload} reload={reload} {...props} />}
            {props.view === 'delete' && <AgentDeleteCard agent={agent} shouldReload={shouldReload} reload={reload} {...props}/>}
        </>}
        
        {agent && props.view === 'chat' && <AgentChatCard agent={agent} shouldReload={shouldReload} reload={reload} {...props}/>}
        {agent &&props.view === 'home' && 
                <Row className="pt-2 pb-2">
                    <Col className="text-center">
                        <Link to={`/agent/${props.agent_id}/chat`}>
                            <Button variant="primary">Chat Now!</Button>
                        </Link>
                    </Col>
                </Row>}
        {props.view === 'home' && agent && agent.owner_id !== props.multiverse.user?.id && <IsDeveloper>
            <b>[Developer View]</b>
            <AgentDeveloperCard agent={agent} shouldReload={shouldReload} reload={reload} {...props}/></IsDeveloper>}
        {!agent && props.view === 'crafting' && <AgentCraftingCard agent={agent} shouldReload={shouldReload} reload={reload} {...props}/>}
        {agent && props.view === 'dev' && <IsDeveloper><AgentDeveloperCard agent={agent} shouldReload={shouldReload} reload={reload} {...props}/></IsDeveloper>}
    </div>
}

type AgentPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type AgentPageState = {
};

class _AgentPage extends React.Component<AgentPageProps, AgentPageState> {
    constructor(props: AgentPageProps) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount(): Promise<void> {

    }

    render(): JSX.Element {
        // if path is"/agent/:agent_id" then set agent_id to the agent_id, othwerwise undefined to create a new one
        let targ_agent_id : any = undefined;
        let view : AgentView = 'home'
        if( this.props.history.location.pathname.includes("/agent/")) {
            // split path into / agent / id / view
            const path_parts = this.props.history.location.pathname.split("/"); 
            // first part is empty, second part is "agent"!
            // agent_id is the third part of the path
            targ_agent_id = path_parts[2];
            if(path_parts.length > 3) {
                // if there is a fourth part, it is the view
                view = path_parts[3] as AgentView
            }
        }

        else if (this.props.history.location.pathname.includes("/createagent")) {
            // if path is "/createagent" then set agent_id to undefined to create a new agent
            targ_agent_id = undefined
            view = 'crafting'
        }

        return (
            <BasicPage>
                <AgentComponent agent_id={targ_agent_id} view={view} {...this.props}/>
            </BasicPage>
        )
    }
}
export const AgentPage = withRouter(withMultiverseApi(_AgentPage));

export default AgentPage;
