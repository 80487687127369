import React , {useEffect, useRef, useState} from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseFile, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { Accordion, Breadcrumb, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { MarkdownWrappedCode } from '../../components/markdown/MarkdownWrappedCode';


type TAgentPlatform = {
    platform: 'Multiverse' | 'Discord',
    actor_id: string,
}
type TAgentTokens = {
    total_tokens: number,
    completion_tokens: number,
    prompt_tokens: number,
}
type TModule = {
    _id: string,
    display_name: string,
    ai_prompt: string
}
type TModuleType = {
    _id: string,
    display_name: string,
    ai_prompt: string
}
type TModulesByType = {
    type: TModuleType,
    modules: TModule[]
}

type TAgent = {
    _id?: string,
    owner_id: {
        _id: string,
        nickname: string,
        iconblob: string
    },
    display_name?: string,
    display_tagline?: string,
    personality?: string,
    speech_style?: string,

    tts_sex?: string,
    tts_language?: string,
    prompt?: string,
    faceprompt?: string,
    faceicon_blobid?: string,
    faceicon_blobids_archive?: string[],
    timestamp?: string,
    accumulated_crafting_cost_mc?: number,
    // ensure at least an empty array is present
    platforms?: TAgentPlatform[]
    tokens?: TAgentTokens

    modules_by_type: TModulesByType[]
}

type AdminAgentsPageProps = {
} & WithMultiverseApiProps & RouteComponentProps;


function _AdminAgentsPage( props: AdminAgentsPageProps) {
    const [agentId, setAgentId] = useState<string>();
    const [agent, setAgent] = useState<TAgent>();
    const [loading, setLoading] = useState<boolean>(true);
    
    useEffect(() => {
        // get id from path
        const path = props.location.pathname.split("/");
        const _id = path[path.length - 1];
        setAgentId(_id);
        
        props.multiverse.get<TAgent>(`/v2/admin/agents/agentdata/${_id}`).then((memory) => {
            console.log(memory);
            setAgent(memory);
            setLoading(false);
        }).catch((err) => {
            console.error(err);
            setLoading(false);
        })
    }, []);
    
    
    return (
        <AdminPage>
        <h4><b><Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/agents" }}>Agents</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/agents/view" }}>View Agents</Breadcrumb.Item>
        <Breadcrumb.Item active>View Agent</Breadcrumb.Item>
        </Breadcrumb></b></h4>
        
        <Card>
            {loading && <Spinner animation="border" />}
            {!loading && agent && <>
                <Card.Header>
                    <h4>Agent: {agent.display_name}</h4>
                </Card.Header>
                <Card.Body>
                    <Card>
                    <Card.Header>Public Data <Button className='ml-3' variant='outline-primary' href={`/agent/${agent._id}`}>View Public Profile</Button> </Card.Header>
                    <Card.Body><small>
                        <b>Profile Image: </b><img src={agent.faceicon_blobid} width='100px' alt="icon"/><br/>
                        <b>Display Name: </b>{agent.display_name}<br/>
                        <b>Tagline: </b>{agent.display_tagline}<br/>
                        <b>Personality: </b>{agent.personality}<br/>
                        <b>Speech Style: </b>{agent.speech_style}<br/>
                        <b>Owner: </b><img src={agent.owner_id.iconblob} width='50px'/> {agent.owner_id.nickname}<br/>
                    </small></Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>Private Data</Card.Header>
                        <Card.Body style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}><small>
                            <b>Timestamp: </b>{agent.timestamp}<br/>
                            <b>TTS Sex: </b>{agent.tts_sex}<br/>
                            <b>TTS Language: </b>{agent.tts_language}<br/>
                            <b>Available Modules: </b>
                            <p style={{ marginLeft: '2rem' }}>{agent.modules_by_type.map((mt) => <div key={mt.type._id}>
                                <b>{mt.type.display_name}</b><br/>
                                <p style={{ marginLeft: '2rem' }}>
                                {mt.modules.map((m) => <div key={m._id}><b>{m.display_name}: </b>{m.ai_prompt}</div>)}
                                </p>
                            </div>)}
                            </p>
                            <b>Prompt: </b><MarkdownWrappedCode>{agent.prompt || ""}</MarkdownWrappedCode>
                            <b>Face Prompt: </b><MarkdownWrappedCode>{agent.faceprompt || ""}</MarkdownWrappedCode><br/> 
                            <b>Accumulated Crafting Cost: </b>{agent.accumulated_crafting_cost_mc}<br/>
                            <b>Platforms: </b>{agent.platforms?.map((p) => p.platform).join(", ")}<br/>
                            <b>Tokens: </b>{agent.tokens?.total_tokens} total, {agent.tokens?.completion_tokens} completion, {agent.tokens?.prompt_tokens} prompt<br/>
                            <b>Face Icon Blobids Archive: </b>{agent.faceicon_blobids_archive?.map((f) => <img src={f} width='50px' alt="icon"/>)}<br/>
                        </small></Card.Body>
                    </Card>

                </Card.Body>
            </>}
        </Card>
        
        </AdminPage>
    );
}
export const AdminAgentsViewAgentPage = withRouter(withMultiverseApi(_AdminAgentsPage));

export default AdminAgentsViewAgentPage;

