import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container, Accordion, OverlayTrigger, Tooltip, Button, DropdownButton, Dropdown, Table, Spinner, Form } from 'react-bootstrap';
//import './discoverycontentcard.css'
import IsDeveloper, { IsNotDeveloper } from '../isDeveloper';
import { useEntity } from '../../utils/entitycache';
import { EntityImageUploadBox } from '../entityImageUploadBox';
import { EntityTextAreaPropertyBox } from '../entityTextAreaPropertyBox';
import { isLockedEvent, MultiverseBaseEntity, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { EntitySelect } from '../entitySelect';
import { MultiverseLocation, MultiverseRoom, MultiverseDomain, MultiversePlan } from "../../hoc/multiverseApiProvider";
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import DialogBox from '../dialogBox';
import PageTable, { PageTableCell } from '../pageTable';
import { set } from 'date-fns';


type TActorSelection = {
    actor_id: string,
    display_name: string,
    display_tagline: string,
    faceicon_url: string
}

type TAvailableActorsResult = {
    actors: TActorSelection[],
    max_actors: number,
    result: string
}

type TCustomConversation = {
    // array of string pairs, first is actor name, second is dialogue line
    conversation: string[][]
}

type TGetContextConfig = {
    uri: string,
    mvbots_option: string,
    actors?: TActorSelection[],
    persistent_actor_ids?: string[],
    user_topics?: string[],
    autospawn?: boolean
    domain_location_overrides : string[] // urls
    location_domain_mvbots_option: string // the parent domain's setting for a location ( in case of inheritance )
}

type TPostContextConfig = {
    uri?: string,
    mvbots_option?: string,
    actor_ids?: string[],
    persistent_actor_ids?: string[],
    user_topics?: string[],
    autospawn?: boolean
}

type MVBotsUserConfigCardProps = {
    targeturl: string
} & WithMultiverseApiProps;

const ActorSelectionDialog = ( props: {actors: TActorSelection[], onSelect: (actor_id: string) => void, onCancel: () => void} ) => {
    return <DialogBox title="Select AI Agent" show={true} onCancel={props.onCancel} closeButton={true}>
        <PageTable>
            <tbody>
            {props.actors.map((actor) => {
                return <PageTable.Row>
                    <td><img src={actor.faceicon_url} width="64" height="64"/> </td>
                    <PageTable.InfoCell title={actor.display_name} subtitle={actor.display_tagline} />
                    <PageTable.Cell><Button variant="outline-primary" onClick={() => {props.onSelect(actor.actor_id)}}>Select</Button></PageTable.Cell>
                </PageTable.Row>
            })}
            {props.actors.length === 0 && <PageTable.Row><td colSpan={3}>Create more Agents to select!</td></PageTable.Row>}
            </tbody>
        </PageTable>
    </DialogBox>
}

const ViewConversationDialog = ( props: {conversation: TCustomConversation, onCancel: () => void} ) => {
    // show a popup dialog with a list in a table of actors to choose from
    return <DialogBox title="Next Conversation" show={true} onCancel={props.onCancel} closeButton={true}>
        <Table>
            <tbody>
            {props.conversation.conversation.map((line) => {
                return <tr>
                    <td>{line[0]}</td>
                    <td>{line[1]}</td>
                </tr>
            })}
            </tbody>
        </Table>
    </DialogBox>
}

const ConfirmResetOverridesDialog = ( props: {onConfirm: () => void, onCancel: () => void} ) => {
    return <DialogBox title="Confirm Reset Location Overrides" show={true} onConfirm={props.onConfirm} onCancel={props.onCancel} closeButton={true}
        type="danger" button0Text='Reset' cancelButtonText='Cancel'>
        <p>Are you sure you want to reset all location overrides to inherit from the parent domain?</p>
        <p>This cannot be undone!</p>
    </DialogBox>
}

export function _MVBotsUserConfigCard(props: MVBotsUserConfigCardProps) { 
    const entity = useEntity<MultiverseBaseEntity>(props.targeturl)

    // domain uri is first split before forward slash
    const domainuri = props.targeturl.split("/")[0]
    const domain = useEntity<MultiverseDomain>(domainuri)
    const location = useEntity<MultiverseLocation>(props.targeturl)
    //const plan = await get<MultiversePlan>(`/v2/domains/${domainuri}/plan`);
    const domainorlocationstring = entity?.type === "location" ? "location" : "metaverse"
    const isDomain = entity?.type === "domain"

    const [editableConfig, setEditableConfig] = useState<TPostContextConfig>({})

    const [actorInfos, setActorsInfos] = useState<TActorSelection[]>([])
    const [domainOverrides, setDomainOverrides] = useState<string[]>([])
    const [parentDomainMVBotsOption, setParentDomainMVBotsOption] = useState<string | null>(null)

    const [availableActorInfos, setAvailableActorsInfos] = useState<TActorSelection[]>([])
    const [maxActors, setMaxActors] = useState<number>(0)
    const [availableReason, setAvailableReason] = useState<string>("")

    const [latestConversation, setLatestConversation] = useState<TCustomConversation | null>(null)
    const [conversationDialogVisible, setConversationDialogVisible] = useState<boolean>(false)

    const [serverop_msg, setServerOpMsg] = useState<string | null>(null)

    const bot_options = isDomain ? [
        {id:"inherit", desc:"Disabled"},
        {id:"custom", desc:"Custom AI Agents"},
    ] : [
        {id:"inherit", desc: isDomain ? "Disabled" : "Inherit from Metaverse"},
        {id:"custom", desc:"Custom AI Agents"},
        {id:"disabled", desc:"Disabled"},
    ]
    const [modifiedFlag, setModifiedFlag] = useState<boolean>(false)

    const [actorSelectionDialogVisible, setActorSelectionDialogVisible] = useState<boolean>(false)
    const [confirmResetDialogVisible, setConfirmResetDialogVisible] = useState<boolean>(false)

    // const [plan, setPlan] = useState<MultiversePlan | null>(null)

    const [needsRefresh, setNeedsRefresh] = useState<boolean>(false)


    // called when page loaded
    useEffect(() => {
        // get location context data. if ay, from the server
        const fetchContextConfig = async () => {
            // get the context config from the server
            try{
                // const plan = await props.multiverse.get<MultiversePlan>(`/v2/domains/${domainuri}/plan`);
                // setPlan(plan)

                const contextconfig = await props.multiverse.get<TGetContextConfig | null>(`/v2/mvbots/location/context/manage?location_uri=${props.targeturl}`)
                setServerOpMsg(null)
                // copy into modified object, but mapping the actors to an array of actor_ids
                if(contextconfig) {
                    setDomainOverrides(contextconfig.domain_location_overrides)
                    setParentDomainMVBotsOption(contextconfig.location_domain_mvbots_option)
                    if(contextconfig.actors) {
                        setActorsInfos([...contextconfig.actors])
                    }
                    // copy required fields to editableConfig (excludes actors, overrides, etc)
                    let config:TPostContextConfig = {
                        uri: contextconfig.uri,
                        mvbots_option: contextconfig.mvbots_option,
                        persistent_actor_ids: contextconfig.persistent_actor_ids || undefined,
                        user_topics: contextconfig.user_topics || undefined,
                        autospawn: contextconfig.autospawn || undefined
                    }
                    // get actor ids
                    const actor_ids = contextconfig?.actors?.map((actor) => actor.actor_id)
                    if( actor_ids ) {
                        config.actor_ids = actor_ids
                    }
                    setEditableConfig(config)
                }

            } catch (e) {
                // create a new object
                setEditableConfig({uri:props.targeturl, mvbots_option:"inherit"})
            }

            // get all available actors, this is different at metaverse or location level depending on plan
            const url = isDomain ? `/v2/domains/${domainuri}/mvbots/availableactors` : `/v2/domains/${props.targeturl}/mvbots/availableactors`
            const available_actors = await props.multiverse.get<TAvailableActorsResult>(url)
            setAvailableActorsInfos(available_actors.actors)
            setMaxActors(available_actors.max_actors)
            setAvailableReason(available_actors.result)

            setNeedsRefresh(false)
        }
        fetchContextConfig();
    }, [needsRefresh===true]); // Empty dependency array ensures it runs once

    const onMVBotsOptionSelected = (option:string | null) => {
        if(option) {
            // update to modified object
            setEditableConfig({...editableConfig, mvbots_option: option})
            setModifiedFlag(true)
        }
    }

    const onResetOverridesClicked = async () => {
        setConfirmResetDialogVisible(true)
    }

    const onConfirmResetOverrides = async () => {
        setServerOpMsg("Resetting location overrides...")
        try {
            // reset the location overrides
            const result = await props.multiverse.get(`/v2/mvbots/location/context/manage/resetoverrides?location_uri=${props.targeturl}`)
            setNeedsRefresh(true)
        } catch (e) {
            alert(e)
        }
        setServerOpMsg(null)
        setConfirmResetDialogVisible(false)
    }

    const onActorDeleteClicked = (actor_id:string) => {
        // update editable object
        setEditableConfig({...editableConfig, actor_ids: editableConfig.actor_ids?.filter((id) => id !== actor_id)})
        setModifiedFlag(true)
    }

    const onAddActorClicked = () => {
        setActorSelectionDialogVisible(true)
    }

    const onAddSelectedActor = (actor_id:string) => {
        // add to modified object
        if(!editableConfig.actor_ids)
            setEditableConfig({...editableConfig, actor_ids: [actor_id]})
        else
            setEditableConfig({...editableConfig, actor_ids: [...editableConfig.actor_ids, actor_id]})
        setModifiedFlag(true)
        setActorSelectionDialogVisible(false)
    }

    const onPersistentChanged = (event:any) => {
        // add to modified object
        if(event.target.checked) {
            if(!editableConfig.persistent_actor_ids)
                setEditableConfig({...editableConfig, persistent_actor_ids: [event.target.id]})
            else
                setEditableConfig({...editableConfig, persistent_actor_ids: [...editableConfig.persistent_actor_ids, event.target.id]})
        } else {
            setEditableConfig({...editableConfig, persistent_actor_ids: editableConfig.persistent_actor_ids?.filter((id) => id !== event.target.id)})
        }
        setModifiedFlag(true)
    }

    const onTopicsListChanged = (event:any) => {
        const topics = event.target.value.split("\n")
        // add to modified object
        setEditableConfig({...editableConfig, user_topics: topics})
        setModifiedFlag(true)
    }

    const onAutoSpawnToggled = (event:any) => {
        // add to modified object
        setEditableConfig({...editableConfig, autospawn: event.target.checked})
        setModifiedFlag(true)
    }

    const onSaveModifiedClicked = async () => {
        setServerOpMsg("Saving changes...")
        try {
            // save the modified object to the server
            // sanitise data if custom bots selected
            if(editableConfig?.mvbots_option === "custom") {
                setServerOpMsg("Verifying AI Agents...")
                // sanitize the topics input, removing any empty lines, etc
                if(editableConfig.user_topics) {
                    const topics = editableConfig?.user_topics?.map((line:string) => line.trim()).filter((line:string) => line.length > 0)
                    editableConfig.user_topics = topics
                }
            }
            const result = await props.multiverse.post(`/v2/mvbots/location/context/manage`, {location_uri:`${props.targeturl}`, ...editableConfig})
            setModifiedFlag(false)
            setNeedsRefresh(true)
        } catch (e) {
            alert(e)
        }
        setServerOpMsg(null)
    }

    const viewNextConversation = async () => {
        // get next conversation, if any
        const conversation = await props.multiverse.get<TCustomConversation>(`/v2/mvbots/location/context/conversation?location_uri=${props.targeturl}`)
        setLatestConversation(conversation)
        setConversationDialogVisible(true)
    }

    const botsAvailable = () => {
        return availableActorInfos && availableActorInfos.length > 0
    }
    const canManangeBots = () => {
        return availableReason !== "needsupgrade"
    }


    return ( 
        <Accordion defaultActiveKey="0">
            <Card className="mt-2 discovery-content-card">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    <div>Multiverse AI Agents</div>
                </Accordion.Toggle>
                {!canManangeBots() && <Accordion.Collapse as={Card.Body} eventKey="0">
                    <div className="p-3">Upgrade your plan to manage AI Agents in this {domainorlocationstring}!</div>
                </Accordion.Collapse> }
                {canManangeBots() && <Accordion.Collapse as={Card.Body} eventKey="0">
                    <Container>
                        {/* Blocking Overlay */}
                        {serverop_msg!==null && (
                            <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.25)', // Optional: a slight tint
                                zIndex: 9999,
                                pointerEvents: 'all', // Blocks all interactions
                            }}
                            />
                        )}

                        <br/>
                        Select AI Agent behaviour for this { domainorlocationstring}:
                        <Row className="ml-1">
                        <DropdownButton id="dropdown-basic-button" title={bot_options.find(x => x.id === editableConfig.mvbots_option)?.desc} variant="outline-primary" onSelect={onMVBotsOptionSelected}>
                            {bot_options.map((option) => {
                                return <Dropdown.Item active={option.id === editableConfig.mvbots_option} eventKey={option.id}>{option.desc}</Dropdown.Item>
                            })}
                        </DropdownButton>
                        {modifiedFlag && <Button variant="primary" className="ml-3" disabled={serverop_msg!==null} onClick={onSaveModifiedClicked}>{serverop_msg || "Save Changes"}</Button>}
                        <Spinner className="ml-3" animation="border" variant="primary" hidden={serverop_msg === null} />
                        </Row>
                        {domainOverrides?.length > 0 && <Row className="ml-1 mt-2">
                            <Button variant='outline-danger' onClick={onResetOverridesClicked}> Reset All {domainOverrides.length} Location Overrides to Inherit Metaverse Behaviour</Button>
                        </Row>}
                        <IsDeveloper>
                        <Row className="ml-1 mt-2">
                            <Button variant="outline-primary" onClick={viewNextConversation}>[dev] View Next Conversation</Button>
                        </Row>
                        </IsDeveloper>
                        {confirmResetDialogVisible && <ConfirmResetOverridesDialog onConfirm={onConfirmResetOverrides} onCancel={() => setConfirmResetDialogVisible(false)} />}
                        {conversationDialogVisible && <ViewConversationDialog conversation={latestConversation!} onCancel={() => setConversationDialogVisible(false)} />}
                        {editableConfig.mvbots_option === "custom" && <>
                        <Card className="mt-3">
                            <Card.Header>
                                <div>Select Available AI Agents</div>
                            </Card.Header>
                            <Card.Body>
                                {/* // create a table where the user can select up to 10 actors, ie. an expandable table showing the actor name etc.
                                // add buttons for adding a new row, or deleting an existing row */}
                                <PageTable>
                                    <tbody>
                                    {editableConfig.actor_ids?.map((actor_id) => {
                                        // get info from actorInfos, from actor_id
                                        const actor = actorInfos.find((actor) => actor.actor_id === actor_id) || availableActorInfos.find((actor) => actor.actor_id === actor_id)
                                        return actor && (
                                            <PageTable.Row key={actor.actor_id}>
                                                { maxActors > 2 && <PageTable.Cell style={{ whiteSpace: 'nowrap', width: '1%' }}>
                                                    <Form><Form.Switch id={actor.actor_id} label="Always Appear" onChange={onPersistentChanged} checked={editableConfig.persistent_actor_ids?.includes(actor.actor_id)}/></Form>
                                                </PageTable.Cell>}
                                                <PageTable.IconCell src={actor.faceicon_url}/>
                                                <PageTable.InfoCell title={actor.display_name} subtitle={actor.display_tagline} />
                                                <PageTable.Cell style={{ whiteSpace: 'nowrap', width: '1%' }}><Button variant="outline-danger" onClick={ () => onActorDeleteClicked(actor.actor_id)} >Remove</Button></PageTable.Cell>
                                            </PageTable.Row> )})}
                                    </tbody>
                                </PageTable>
                                {(!editableConfig.actor_ids || editableConfig.actor_ids.length < maxActors) && <Button variant='outline-primary' onClick = {onAddActorClicked}>Add AI Agent</Button>}
                                {actorSelectionDialogVisible && <ActorSelectionDialog actors={
                                    // filter out any actors already in the list
                                    availableActorInfos.filter((actor) => !editableConfig.actor_ids?.includes(actor.actor_id))
                                    } onSelect={onAddSelectedActor} onCancel={() => setActorSelectionDialogVisible(false)} />}
                            </Card.Body>
                        </Card>

                        <Card className="mt-3">
                            <Card.Header>
                                <div>AI Agent Topics</div>
                            </Card.Header>
                            <Card.Body>
                                <div>
                                    <textarea
                                        style={{width:`100%`, height:`100%`}}
                                        rows={10}
                                        value={editableConfig?.user_topics?.join("\n")}
                                        onChange={onTopicsListChanged}
                                        placeholder="Enter AI Agent topics here, one per line..."></textarea>
                                </div>
                            </Card.Body>
                        </Card>
                        { // add a checkbox toggle to enable/disable auto-spawning DEV-ONLY
                        }
                        <IsDeveloper>
                        {/* <Card className="mt-2">
                            <Card.Header>
                                <div>[DEV ONLY] Bot Auto-Spawning</div>
                            </Card.Header>
                            <Card.Body>
                                <div>
                                    <input type="checkbox" onChange={onAutoSpawnToggled} checked={editableConfig.autospawn} />
                                    <label>Enable Auto-Spawning</label>
                                </div>
                            </Card.Body>
                        </Card> */}
                        </IsDeveloper>
                        
                        </>
                        }
                        {editableConfig.mvbots_option !== "custom" &&
                        <Card className="mt-2">
                            <Card.Header>
                                <div>AI Agent Information</div>
                            </Card.Header>
                            <Card.Body>
                                <div>
                                {!isDomain && editableConfig.mvbots_option === "inherit" && <div><b>Inherited Default</b><br/>
                                        This {domainorlocationstring} will inherit the AI Agent settings from the parent Metaverse.<br/>
                                        {parentDomainMVBotsOption &&
                                        `The resulting behaviour is that ${parentDomainMVBotsOption === "custom" ? " custom AI Agents will appear" : " no AI Agents will appear"}` }
                                    </div>}
                                    {/* {editableConfig.mvbots_option === "cliptron" && <div><b>Cliptron Bots Allowed</b><br/>
                                        Cliptron Bots are a set of default bots that are available to all locations.</div>}*/}
                                    {(editableConfig.mvbots_option === "disabled" || isDomain && editableConfig.mvbots_option === "inherit" ) && <div><b>AI Agents Disabled</b><br/>
                                        No AI Agents will appear in your {domainorlocationstring}, and users will not be able to use a paperclip.</div>}
                                </div>
                            </Card.Body>
                        </Card>
                        }
                    <br/>
                    </Container>
                </Accordion.Collapse>}
            </Card>
        </Accordion>
    )
}

export const MVBotsUserConfigCard = withMultiverseApi(_MVBotsUserConfigCard);

export default MVBotsUserConfigCard;
