import React from 'react';
import { Button, Col, Container, Dropdown, DropdownButton, Form, FormControl, Modal, Row, Spinner, Table, Image } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import BasicPage from '../../components/basicPage';
import IsDeveloper from '../../components/isDeveloper';
import IsNotDeveloper from '../../components/isNotDeveloper';
import PageTable from '../../components/pageTable';
import { getApiUrl, getBlobUrl } from '../../config/api';
import { getDomainPlanInfo, MultiverseDomain, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import * as entitycache from '../../utils/entitycache';
//import "./domainspage.css"
import mvicon from '../../assets/mvicon.png';


export interface IAgentSummaryResult {
    agent_id: string;
    display_name: string;
    display_tagline?:string;
    faceicon_url?:string;
    platforms?: string[];
    owner_id?:string;
    owner_name?:string;
    owner_icon_url?:string;
}
interface IAgentsResult {
    agents: IAgentSummaryResult[];
    //max_agents?: number;
}

type Filter = "none" | "owner" | "manager" | "manageronly" | "memberonly";

type AgentsTableProps ={
    filter?: Filter
    group?: boolean
} & WithMultiverseApiProps & RouteComponentProps;

type AgentUsage = {
    platform: string;
    summary: string;
    usages: string[];
}

type AgentsTableState = {
    isLoading: boolean;
    hasError: boolean;
    agents: IAgentSummaryResult[];
    owned_number: number;
    //max_agents?: number;
    showDeleteDialog: boolean;
    agentToDelete?: IAgentSummaryResult;
    agentUsages?: AgentUsage[];
    deleteAgentValidationText: string;
    //canCreate: boolean;
};
 
const TextCellStyle = {
    verticalAlign: "middle"
}
const ButtonCellStyle = {
    width: 50,
    verticalAlign: "middle"
}

class _AgentsTable extends React.Component<AgentsTableProps, AgentsTableState> {
    constructor(props: AgentsTableProps) {
        super(props);
        this.state = {
            isLoading: true,
            hasError: false,
            agents: [],
            owned_number: 0,
            //max_agents: 0,
            showDeleteDialog: false,
            deleteAgentValidationText: "",
            //canCreate: false
        };
    }

    async componentDidMount(): Promise<void> {
        const { multiverse: { user } } = this.props;
        await this.refreshList();
    }

    refreshList = async () => {
        const { multiverse: { get, user } } = this.props;
        try {
            //switch to loading
            this.setState({ isLoading: true });

            // anyone can create agents!
            // // get ability to create agents
            // const r = await get<any>(`/v2/agents/cancreate`)
            // console.log(`CANCREATE: ${r.cancreate}`);
            // this.setState({ canCreate: r.cancreate || false });


            //get and store domains list
            //let agents = this.filterDomainList(await get<IAgentSummaryResult[]>('/v2/agents'), this.props.filter);
            let url = '/v2/agents'
            let agents_res = await get<IAgentsResult>(url);
            let agents = agents_res.agents;
            agents.sort((a,b) => a.display_name.localeCompare(b.display_name));
            this.setState({ agents });
            //this.setState({max_agents: agents_res.max_agents});
            this.setState({owned_number: agents.length});

        } catch (e) {
            console.log(e);
            this.setState({ hasError: true });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    renderrows_noagents() {
        return (
            <>
                {this.props.filter !== "memberonly" && <>
                    <Row className="pt-2">
                        <Col className="text-center pb-2">
                            You've got no agents yet! To have your very own customised agents and interact with them in VR and elsewhere, feel free to create and set up some new ones!<br></br>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="text-center">
                            {<Link to="/createagent">
                                <Button variant="outline-primary">Create New Agent</Button>
                            </Link>}
                            {/* {!this.state.canCreate && <div><b>Upgrade your plan to create a new Agent!</b></div>} */}
                        </Col>
                    </Row>
                </>}     
            </>
        );
    }

    onRowClick = (x: IAgentSummaryResult) => {
        const { history } = this.props;
        history.push(`/agent/${x.agent_id}`)
    }

    renderTableContainerRow = (agents: IAgentSummaryResult[]) => {        
        return (
            agents.map((x) => {
                return (
                    <PageTable.Row key={x.agent_id} onClick={() => this.onRowClick(x)} style={{ cursor: "pointer"}} >
                        <PageTable.IconCell className="dp-icon-cell" src={x.faceicon_url!} roundedCircle style={{ border: "none" }}/> 
                        <PageTable.InfoCell className="dp-name-cell" title={x.display_name} subtitle={x.display_tagline} style={{ border: "none", justifyContent: "flex-end" }}/>
                        <PageTable.Cell style={{ border: "none", textAlign: "right", paddingRight: "0.5rem", alignItems: "center"}}>
                            <div>
                                {x.platforms?.map((platform) => (
                                <img
                                    key={platform}
                                    src={`agentplatform_${platform}.jpg`}
                                    alt={platform}
                                    style={{ width: "20px", height: "20px", borderRadius: "4px", objectFit: "cover" }}
                                />
                                ))}
                            </div>
                        </PageTable.Cell>
                            <PageTable.Cell style={{ fontFamily: "monospace", fontSize: "1.25rem", textAlign: "right", width:"20px", border: "none" }}>{'>'}</PageTable.Cell>
                        {x.owner_icon_url && <PageTable.IconCell className="dp-icon-cell" src={x.owner_icon_url!} roundedCircle style={{ border: "none" }}/>}
                        {x.owner_id && <PageTable.InfoCell className="dp-name-cell" title={x.owner_name} subtitle={x.owner_id} style={{ border: "none", justifyContent: "flex-end" }}/>}
                    </PageTable.Row>
                )
            })
        )
    }

    concatTableContainerRow =  (tables: JSX.Element[], agents: IAgentSummaryResult[]) => {
        if(agents.length > 0) {
            if(tables.length > 0) {
                tables.push(<PageTable.Row style={{height: 15}}/>)
            }
            this.renderTableContainerRow(agents).forEach(x => tables.push(x));
        }
    }

    renderrows_agents() {

        let tables: JSX.Element[] = [];
        // if(this.props.group) {
        //     // this.concatTableContainerRow(tables, this.filterDomainList(this.state.domains, "owner"))            
        //     // this.concatTableContainerRow(tables, this.filterDomainList(this.state.domains, "manageronly"))
        //     // this.concatTableContainerRow(tables, this.filterDomainList(this.state.domains, "memberonly"))
        // } else {
            tables = tables.concat(this.renderTableContainerRow(this.state.agents));
//        }

        return (
            <>
            <Row className="p-0">
                <Col>
                    <PageTable className="m-0">
                        {/* <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th></th>
                                <th></th>
                                {/* <th className="d-none d-sm-table-cell">Type</th>
                                <th className="d-none d-md-table-cell">Membership</th> */}
                            {/* </tr>
                        </thead> */}
                        <tbody>                    
                            {tables}
                        </tbody>
                    </PageTable>
                </Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col className="text-center">
                    {/* {this.state.max_agents > 0 && this.state.owned_number === 0 && <div>You can create up to {this.state.max_agents} agents.</div>} */}
                    {this.state.owned_number > 0 && <div>You own {this.state.owned_number} agents.</div>}
                    <Link to="/createagent">
                        <Button variant="outline-primary">Create New Agent</Button>
                    </Link>
                    {/* {!this.state.canCreate && <div><b>Upgrade your plan to create a new Agent!</b></div>} */}
                </Col>
            </Row>
            </>
        )
    }

    render_rows() {
        if (!this.state.agents)
            return (<pre>error</pre>)
        else if (this.state.agents.length > 0)
            return this.renderrows_agents();
        else
            return this.renderrows_noagents();
    }

    renderLoading() {
        return (
            <Container fluid>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>
        );
    }

    render() {
        const { isLoading } = this.state;
        return (
            <Container fluid className="p-0">
                {/* <IsDeveloper>
                    {this.renderDevShowUnowned()}
                </IsDeveloper> */}
                {!isLoading && this.render_rows()}
                {isLoading && this.renderLoading()}
            </Container>)
    }
}

export const AgentsTable = withRouter(withMultiverseApi(_AgentsTable));
export default AgentsTable;
