import React , {useEffect, useRef, useState} from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseFile, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { Accordion, Breadcrumb, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';




type AdminAgentsPageProps = {
} & WithMultiverseApiProps & RouteComponentProps;

type TAgentTokens = {
    total_tokens: number,
    completion_tokens: number,
    prompt_tokens: number,
}
type UserMemory = {
    _id: string;
    type: string,
    entity_id: {
        _id: string,
        nickname: string,
        iconblob: string
    }
    
    hard_facts: {
        last_updated: string
        name?: string;
        ai_summary?: string;
        metaverse_ownership?: string[];
        metaverse_locations?: {
            [metaverse: string]: string[];  // Metaverse ID/Name -> Array of locations
        };
        [key: string]: any; // extendable for more known facts
        
    };
    interactions?: {
        [agent_id: string]: {
            last_updated: string
            ai_summary?: string;
            extracted_facts?: {
                [key: string]: string;
            };
            // extra derived info not in db
            display_name: string,
            iconblob: string
        };
    };
    
    tokens: TAgentTokens;
};

function _AdminAgentsPage( props: AdminAgentsPageProps) {
    const [memoryId, setMemoryId] = useState<string>();
    const [memory, setMemory] = useState<UserMemory>();
    const [loading, setLoading] = useState<boolean>(true);
    
    useEffect(() => {
        // get id from path
        const path = props.location.pathname.split("/");
        const _id = path[path.length - 1];
        setMemoryId(_id);
        
        props.multiverse.get<UserMemory>(`/v2/admin/agents/memory/${_id}`).then((memory) => {
            console.log(memory);
            setMemory(memory);
            setLoading(false);
        }).catch((err) => {
            console.error(err);
            setLoading(false);
        })
    }, []);
    
    
    return (
        <AdminPage>
        <h4><b><Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/agents" }}>Agents</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/agents/memories" }}>View User Memories</Breadcrumb.Item>
        <Breadcrumb.Item active>View User Memory</Breadcrumb.Item>
        </Breadcrumb></b></h4>
        
        <Card>
            {loading && <Spinner animation="border" />}
            {!loading && memory && <>
                <Card.Header>
                    <h4>User Memory for {memory.entity_id.nickname}</h4>
                </Card.Header>
                <Card.Body>
                    <Card>
                    <Card.Header>Hard Facts <small>(last updated:{memory.hard_facts.last_updated} )</small></Card.Header>
                    <Card.Body><small>
                        <ReactMarkdown>{memory.hard_facts.ai_summary || ""}</ReactMarkdown></small>
                    {/* <p>{memory.hard_facts.metaverse_ownership}</p>
                    <p>{memory.hard_facts.metaverse_locations}</p> */}
                    </Card.Body></Card>

                    <Card>
                    <Card.Header>Interactions</Card.Header>
                    <Card.Body><small>
                        {memory.interactions && Object.keys(memory.interactions).map((agent_id) => {
                            const interaction = memory.interactions![agent_id];
                            return <Card key={agent_id}>
                                <Card.Header><img src={interaction.iconblob} width="32px"/><Link to={`/admin/agents/view/${agent_id}`}> {interaction.display_name}</Link> <small>(last updated: {interaction.last_updated}) {agent_id}</small></Card.Header>
                                <Card.Body>
                                    <ReactMarkdown>{interaction.ai_summary || ""}</ReactMarkdown>
                                    {interaction.extracted_facts && Object.keys(interaction.extracted_facts).map((key) => {
                                        return <><b>{key}</b>: {interaction.extracted_facts![key]}<br/></>
                                    })}
                                </Card.Body>
                            </Card>
                        }
                        )}
                        </small>
                    </Card.Body></Card>
                    

                </Card.Body>
            </>}
        </Card>
        
        </AdminPage>
    );
}
export const AdminAgentsViewMemoryPage = withRouter(withMultiverseApi(_AdminAgentsPage));

export default AdminAgentsViewMemoryPage;

