import React, { ChangeEventHandler, ReactNode, useState, useEffect } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Image, Card, Button, Spinner, Row, Accordion, Container, Col } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { AgentChildComponentProps, AgentRaw } from '../../pages/agentHomePage';
import { set } from 'date-fns';
import { IsAgentOwner } from './agentIsOwner';
import { MarkdownWrappedCode } from '../markdown/MarkdownWrappedCode';


type AgentDevCardProps = {

} & AgentChildComponentProps

type TokenUsage = {
    total_tokens: number,
    completion_tokens: number,
    prompt_tokens: number,
}

type AgentChatPromp = {
    system_prompt?: string,
    user_prompt?: string,
}

export function _AgentDeveloperCard(props: AgentDevCardProps) {

    // get agent data
    const [agentMemoryRaw, setAgentMemoryRaw] = useState<any | undefined>(undefined)
    const [tokenUsage_Generation, setTokenUsage_Generation] = useState<TokenUsage | undefined>(undefined)
    const [tokenUsage_AllConvos, setTokenUsage_AllConvos] = useState<TokenUsage | undefined>(undefined)
    const [tokenUsage_ThisConvo, setTokenUsage_ThisConvo] = useState<TokenUsage | undefined>(undefined)
    const [chatPrompt, setChatPrompt] = useState<AgentChatPromp | undefined>(undefined)

    // get agent data
    const loadData = async() => {
        const res2 = await props.multiverse.get<AgentRaw>(`/v2/admin/agents/agentmemory/${props.agent_id}`)
        console.log(res2)
        setAgentMemoryRaw(res2)

        const res3 = await props.multiverse.get<any>(`/v2/admin/agents/agenttokens?agent_id=${props.agent_id}`)
        console.log(res3)
        setTokenUsage_Generation(res3?.agentgen)
        setTokenUsage_AllConvos(res3?.all_convos)

        const res4 = await props.multiverse.get<AgentChatPromp>(`/v2/admin/agents/agentchatprompt/${props.agent_id}`)
        console.log(res4)
        setChatPrompt(res4)

        return res2;
    }
    useEffect(() => {
        loadData()
        .then(x => {
        })
    }
    ,[props.shouldReload])

    async function onResetInteractions() {
        // reset interactions
        const res = await props.multiverse.get(`/v2/admin/agents/agentmemory/${props.agent_id}/reset_interactions`)
        console.log(res)
        loadData()
    }

    async function onResetHardFacts() {
        // reset hard facts
        const res = await props.multiverse.get(`/v2/admin/agents/agentmemory/${props.agent_id}/reset_hard_facts`)
        console.log(res)
        loadData()
    }

    async function onDeleteMemory() {
        // delete memory
        const res = await props.multiverse.del(`/v2/admin/agents/agentmemory/${props.agent_id}`)
        console.log(res)
        loadData()
    }


    return (

        <Accordion defaultActiveKey={undefined}>

            <Card className="mt-2 discovery-content-card">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    <div>Agent Chat Prompt <small>▼</small></div>
                </Accordion.Toggle>
                <Accordion.Collapse as={Card.Body} eventKey="0"><small>
                    <b>System Prompt:</b>
                    <MarkdownWrappedCode>
                        {chatPrompt?.system_prompt || ""}
                    </MarkdownWrappedCode>
                    <b>User Prompt:</b>
                    <MarkdownWrappedCode>
                    {chatPrompt?.user_prompt || ""}
                    </MarkdownWrappedCode>
                    </small>
                </Accordion.Collapse>
            </Card>

        <Card className="mt-2 discovery-content-card">
            <Accordion.Toggle as={Card.Header} eventKey="1">
                <div>Agent Tokens Usage <small>▼</small></div>
                </Accordion.Toggle>
            <Accordion.Collapse as={Card.Body} eventKey="1"><>
                {tokenUsage_Generation && 
                    <div>
                        <div><b>Generation Tokens</b></div>
                        <div>Total Tokens: {tokenUsage_Generation.total_tokens} - 
                        Completion Tokens: {tokenUsage_Generation.completion_tokens},
                        Prompt Tokens: {tokenUsage_Generation.prompt_tokens}</div>
                    </div>
                }
                {tokenUsage_AllConvos && 
                    <div>
                        <div><b>All Conversations Tokens</b></div>
                        <div>Total Tokens: {tokenUsage_AllConvos.total_tokens} - 
                        Completion Tokens: {tokenUsage_AllConvos.completion_tokens},
                        Prompt Tokens: {tokenUsage_AllConvos.prompt_tokens}</div>
                    </div>
                }</>
            </Accordion.Collapse>
        </Card>
        <Row className="mt-2"><Col className="text-center">
            <Button variant="outline-primary" href={`/admin/agents/view/${props.agent_id}`}>Admin View</Button>
        </Col></Row>
    </Accordion> )
}
export const AgentDeveloperCard =_AgentDeveloperCard;

export default AgentDeveloperCard;
