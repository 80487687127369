import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';


type InventorySummaryProps = {
} & WithMultiverseApiProps;


export function _UserInventorySummary(props: InventorySummaryProps) {
    const { inventory } = props.multiverse

    const formattedPrice = new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        //currency: "mc", // Change currency as needed
      })

    return ( 
            <Form className="profile-icon-username">
                <Form.Label className='me-2 text-end'>{formattedPrice.format(inventory?.currency || 0)}mc</Form.Label>
            </Form>
    )
    
}

export const UserInventorySummary = withMultiverseApi(_UserInventorySummary);

export default UserInventorySummary;

