import React, { useEffect } from 'react';
import { useMultiverseContext } from '../../hoc/multiverseApiProvider'

export function IsAgentOwner(props: React.PropsWithChildren<{ agent_id: string }>) {
    const context = useMultiverseContext()


    const [isOwner, setIsOwner] = React.useState<boolean>(false);

  useEffect(() => {
    const loadData = async() => {
        const res = await context.multiverse.get<any>(`/v2/agents/isowner/${props.agent_id}`)
        console.log(res)
        return res;
    }
    if(props.agent_id) {
        loadData()
        .then(x => {
            setIsOwner(x.isowner)
        })
        .catch(err => {
            setIsOwner(false)
            console.log(err)
        })
    }
  }, [props.agent_id])

  return <>
    {isOwner && props.children}
    </>
};

export function IsNotAgentOwner(props: React.PropsWithChildren<{ agent_id: string }>) {
    const context = useMultiverseContext()


    const [isOwner, setIsOwner] = React.useState<boolean>(false);

  useEffect(() => {
    const loadData = async() => {
        const res = await context.multiverse.get<any>(`/v2/agents/isowner/${props.agent_id}`)
        console.log(res)
        return res;
    }
    if(props.agent_id) {
        loadData()
        .then(x => {
            setIsOwner(x.isowner)
        })
        .catch(err => {
            setIsOwner(false)
            console.log(err)
        })
    }
  }, [props.agent_id])

  return <>
    {!isOwner && props.children}
    </>
};
