import React , {useEffect, useRef, useState} from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseFile, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { Accordion, Breadcrumb, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import PageTable from '../../components/pageTable';
import TextareaAutosize from 'react-textarea-autosize';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import ReactJson from 'react-json-view';
import api from '../../utils/api';
import { FileDrop } from 'react-file-drop';
import { on } from 'process';
import DialogBox from '../../components/dialogBox';
import DialogBoxWithSpinner from '../../components/dialogBoxWithSpinner';
import { th } from 'date-fns/locale';
import { propTypes } from 'react-bootstrap/esm/Image';
import { max } from 'date-fns';
import { AgentsAdminOverviewCard } from '../../components/agentsadmin/agentsAdminOverviewCard';

type AdminAgentsPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type AdminAgentsPageState = {
    serverop_msg: string | null
};




function _AdminAgentsPage( props: AdminAgentsPageProps) {


  return (
    <AdminPage>
      <h4><b><Breadcrumb>
          <Breadcrumb.Item active>Agents</Breadcrumb.Item>
      </Breadcrumb></b></h4>
      <Card className='mb-3'>
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <Button variant="outline-primary" href="/admin/agents/view">View Agents</Button>
              <Button className="ml-3" variant="outline-primary" href="/admin/agents/memories">View User Memories</Button>
              <Button className="ml-3" variant="outline-primary" href="/admin/agents/modules">Manage Modules</Button>
              <Button className="ml-3" variant="outline-primary" href="/admin/agents/configs">Manage Configs</Button>
            </Col>
          </Row>
          </Card.Body>
      </Card>
      <AgentsAdminOverviewCard {...props} />
    </AdminPage>
  );
}
export const AdminAgentsPage = withRouter(withMultiverseApi(_AdminAgentsPage));

export default AdminAgentsPage;

