import React , {useRef, useState} from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseFile, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { Accordion, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import PageTable from '../../components/pageTable';
import TextareaAutosize from 'react-textarea-autosize';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactJson from 'react-json-view';
import api from '../../utils/api';
import { FileDrop } from 'react-file-drop';
import { on } from 'process';
import DialogBox from '../../components/dialogBox';
import DialogBoxWithSpinner from '../../components/dialogBoxWithSpinner';
import { th } from 'date-fns/locale';
import { propTypes } from 'react-bootstrap/esm/Image';

type AdminMVBotsPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type AdminMVBotsPageState = {
    alltopics_summary: AllTopicsSummaryRow[] | null,
    allactors_summary: AllActorsSummaryRow[] | null
    allcontexts_summary: AllLocationContextsSummaryRow[] | null
    allprompts: SystemPrompt[] | null

    serverop_msg: string | null
};


type UploaderParams = {
    post_uri: string,
    append_filename: boolean,
    context: _AdminMVBotsPage,
}

type AllTopicsSummaryRow = {
  collection_name: string,
  topics_count: number
}

type AllActorsSummaryRow = {
  actor_id: string,
  display_name: string,
  display_tagline: string,
  faceicon_url: string,
  availability: string
}

type AllLocationContextsSummaryRow = {
  location_uri: string,
  topics_id: string,
  autospawn: boolean,
  autospawn_constrain_topics: boolean,
}

type SystemPrompt = {
  prompt_id: string,
  title: string,
  system_prompt: string,
  user_prompt: string
}

const ActorAvailabilityOptions = [
    "devonly",
    "public",
    "paperclip"
]



const JsonUploaderWithViewer = (p: UploaderParams) => {
  const [jsonData, setJsonData] = useState<Record<string, unknown> | null>(null);
  const params = p;
  const [fileName, setFileName] = useState<string | null>(null);

  // Handle file upload
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target?.result as string);
          setJsonData(json); // Set uploaded JSON data
            // Set uploaded file name, extension removed
            setFileName(file.name.replace(/\.[^/.]+$/, ''));
        } catch (error) {
          alert('Invalid JSON file');
        }
      };
      reader.readAsText(file);
    }
  };

  // Handle edit in ReactJson
  const handleEdit = (edit: any) => {
    setJsonData(edit.updated_src); // Update JSON state after editing
  };

  // Send JSON to the backend
  const handleUploadToServer = async () => {
    if (jsonData) {
      params.context.setState({serverop_msg: `Uploading JSON...`})
      try {
        const url = params.append_filename ? `${params.post_uri}/${fileName}` : params.post_uri;
        const response = await api.post<any>({
        url: url,
        headers: {
            'Content-Type': 'text/plain',
          },
          body: jsonData,
        });
        
        // const response = await fetch(`${params.post_uri}/${fileName}`, {
        //   method: 'POST',
        if (response.ok) {
          //alert('JSON uploaded successfully!');
          setJsonData(null); // Clear JSON data after successful upload
          setFileName(null); // Clear file name after successful upload
          params.context.refreshOverview();
        } else {
          alert('Failed to upload JSON.');
        }
      } catch (error) {
        alert(`Error uploading JSON:\n${error}`);
      }
    } else {
      alert('No JSON to upload!');
    }
    params.context.setState({serverop_msg: null})
  };

  return (
    <div>
      {/* File input for JSON upload */}
      <input
        type="file"
        accept=".json"
        onChange={handleFileUpload}
        style={{ marginBottom: '10px' }}
      />

      {/* JSON Viewer/Editor */}
      {jsonData ? (
        <ReactJson
          src={jsonData}
          onEdit={handleEdit}
          onAdd={handleEdit}
          onDelete={handleEdit}
          theme="monokai"
          enableClipboard={true}
          displayDataTypes={false}
        />
      ) : (
        <p>Choose a JSON file to view, before sending to the server.</p>
      )}

      {/* Upload button */}
      <button
        onClick={handleUploadToServer}
        style={{
          marginTop: '10px',
          padding: '10px',
          backgroundColor: '#007BFF',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Send JSON to Server
      </button>
    </div>
  );
};

const FaceIconUploader = (props: {actor_id: string}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
        upload(files);
    }
  }

  const onDropFiles = (files: FileList | null, event: React.DragEvent<HTMLDivElement>) => {
    if (files) {
        upload(files);
    }
  }

  const resetUploadInput = () => {
    if(fileInputRef.current)
        fileInputRef.current.value = "";
  }


  const upload = async(files: FileList) => {
    // upload face icon as blob
    const file = files[0];
    try {
        const response = await api.post<MultiverseFile>({
            url: `/v2/mvbots/actor/faceicon/${props.actor_id}`,
            headers: {
              'Content-Type': file.type,
              'x-shapevr-name': file.name,
              },
            body: file,
        });
        if (response) {
            alert('Face Icon uploaded successfully!');
        } else {
            alert('Failed to upload Face Icon.');
        }
    } catch (error) {
        alert(`Error uploading Face Icon:\n${error}`);
    }
    resetUploadInput();
  }

  return (
    <FileDrop
        onDrop={onDropFiles}>
        <input
            onChange={onFileInputChange}
            ref={fileInputRef}
            type="file"
            accept="image/*"
        />
    <Button variant='outline-secondary' onClick={async () => {fileInputRef.current?.click()}}>Upload Face Icon</Button>
    </FileDrop>
  );
}

// editabe system prompt, all fields editable, with 2 buttona for preview and send to server
// onChange prop to update prompt in parent state
/*
const SystemPromptEditor = (props: {prompt: SystemPrompt, onChange: (prompt: SystemPrompt) => void}) => {
  // update prompt_id, title, system_prompt, user_prompt
  return (
    <Card>
      <Card.Body>
        <Button variant='outline-secondary'>Preview</Button>
        <Button variant='primary'>Send to Server</Button>
        <Form>
          // add editable prompt_id and title fields
          <Form.Group>
            <Form.Label>Prompt ID - <b>Warning! Changing this will result in a NEW prompt created!</b></Form.Label>
            <Form.Control type="text" value={props.prompt.prompt_id} onChange={(e) => {
              // update prompt_id in prompt
            }}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" value={props.prompt.title} onChange={(e) => {
              // update title in prompt, and set in all prompts state
              props.prompt.title = e.target.value
              //props.onChange(props.prompt)
            }}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>System Prompt</Form.Label>
            <TextareaAutosize className="form-control" value={props.prompt.system_prompt} onChange={(e) => {
              // update system_prompt in prompt

            }}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>User Prompt</Form.Label>
            <TextareaAutosize className="form-control" value={props.prompt.user_prompt} onChange={(e) => {
              // update user_prompt in prompt
            }}/>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  )
}
*/

class _AdminMVBotsPage extends React.Component<AdminMVBotsPageProps, AdminMVBotsPageState> {
    constructor(props: AdminMVBotsPageProps) {
        super(props);
        this.state = {
            alltopics_summary: null,
            allactors_summary: null,
            allcontexts_summary: null,
            allprompts: null,
            serverop_msg: null,
        };
    }

    json_src = {}

    async componentDidMount(): Promise<void> {
        await this.refreshOverview();
    }

    async refreshOverview(): Promise<void> {
        try {
            const result = await this.props.multiverse.get<AllTopicsSummaryRow[]>(`/v2/mvbots/conversation/alltopics-summary`)
            this.setState({alltopics_summary: result})
        } catch {
          this.setState({alltopics_summary: []})
        }
        try {
          const result2 = await this.props.multiverse.get<AllActorsSummaryRow[]>(`/v2/mvbots/actor/allconfigs-summary`)
          this.setState({allactors_summary: result2})
        } catch {
          this.setState({allactors_summary: []})
        }
        try {
          const result3 = await this.props.multiverse.get<AllLocationContextsSummaryRow[]>(`/v2/mvbots/location/allcontexts-summary`)
          this.setState({allcontexts_summary: result3})
        } catch {
          this.setState({allcontexts_summary: []})
        }
        try {
          const result4 = await this.props.multiverse.get<SystemPrompt[]>(`/v2/mvbots/systemprompts/all`)
          this.setState({allprompts: result4})
        } catch {
          this.setState({allprompts: []})
        }
    }

    defaultNow = () => {
        let now = new Date();
        now.setUTCHours(0);
        now.setUTCMinutes(0);
        now.setUTCSeconds(0);
        now.setUTCMilliseconds(0);
        return now;
    }


    onTopicsViewClick = async (collection_name: string) => {
        console.log(`View details: ${collection_name}`)
        //this.props.history.push(`/admin/redeemcodes/listcodes/${usage}`)
        // get topics list, display as plain text in a new window
        const res = await this.props.multiverse.get<any>(`/v2/mvbots/conversation/topics/${collection_name}`)
        console.log(res)
        let topics = res.topics;
        let topics_text = topics.join("\n");
        let win = window.open("", "_blank");
        win?.document.write(`<pre>${topics_text}</pre>`);
    }

    onTopicsDownloadClick = async (collection_name: string) => {
        console.log(`Download: ${collection_name}`)
        // get topics list, save results directly to file
        const res = await this.props.multiverse.get<any>(`/v2/mvbots/conversation/topics/${collection_name}`)
        console.log(res)
        const topics = res.topics;
        const jsonContent = JSON.stringify({ topics }, null, 2); // Beautified JSON
        const blob = new Blob([jsonContent], { type: 'application/json' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${collection_name}.json`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    onTopicsDeleteClick = async (collection_name: string) => {
        this.setState({serverop_msg: `Deleting topics collection...`})
        console.log(`Delete: ${collection_name}`)
        // delete topics collection
        try {
          const res = await this.props.multiverse.del<any>(`/v2/mvbots/conversation/topics/${collection_name}`)
          console.log(res)
          await this.refreshOverview();
        } catch (e) {
            alert(`Error deleting topics collection: ${e}`)
        }
        this.setState({serverop_msg: null})
    }

    onActorViewClick = async (actor_id: string) => {
        console.log(`View details: ${actor_id}`)
        // get actor config, display as plain text in a new window
        const res = await this.props.multiverse.get<any>(`/v2/mvbots/actor/configs/${actor_id}`)
        console.log(res)
        let win = window.open("", "_blank");
        win?.document.write(`<pre>${JSON.stringify(res, null, 2)}</pre>`);
    }

    onActorDeleteClick = async (actor_id: string) => {
        this.setState({serverop_msg: `Deleting actor config...`})
        console.log(`Delete: ${actor_id}`)
        // delete actor config
        try {
          const res = await this.props.multiverse.del<any>(`/v2/mvbots/actor/configs/${actor_id}`)
          console.log(res)
          await this.refreshOverview();
        } catch (e) {
            alert(`Error deleting actor config: ${e}`)
        }
        this.setState({serverop_msg: null})
    }

    onContextViewClick = async (location_uri: string) => {
        console.log(`View details: ${location_uri}`)
        // get location context, display as plain text in a new window
        const res = await this.props.multiverse.get<any>(`/v2/mvbots/location/context?location_uri=${location_uri}`)
        console.log(res)
        let win = window.open("", "_blank");
        win?.document.write(`<pre>${JSON.stringify(res, null, 2)}</pre>`);
    }

    onContextGenerateClick = async (location_uri: string) => {
      this.setState({serverop_msg: `Generating conversation for location...`})
      console.log(`Generate: ${location_uri}`)
        // generate a conversation from location context
        try {
          const res = await this.props.multiverse.get<any>(`/v2/mvbots/conversation/generate?location_uri=${location_uri}`)
          console.log(res)
          let win = window.open("", "_blank");
          win?.document.write(`<pre>${JSON.stringify(res, null, 2)}</pre>`);
        } catch (e) {
            alert(`Error generating conversation: ${e}`)
        }
        this.setState({serverop_msg: null})
    }

    onContextResetClick = async (location_uri: string) => {
      this.setState({serverop_msg: `Resetting conversations for location...`})  
      console.log(`Reset: ${location_uri}`)
        // reset location context
        try {
          const res = await this.props.multiverse.get<any>(`/v2/mvbots/location/context/reset?location_uri=${location_uri}`)
          console.log(res)
          await this.refreshOverview();
        } catch (e) {
            alert(`Error resetting location context: ${e}`)
        }
        this.setState({serverop_msg: null}) 
    }

    onContextDeleteClick = async (location_uri: string) => {
      this.setState({serverop_msg: `Deleting location context...`})  
      console.log(`Delete: ${location_uri}`)
        // delete location context
        try {
          const res = await this.props.multiverse.del<any>({
              url: `/v2/mvbots/location/context`,
              headers: {
                'Content-Type': 'text/plain',
              },
              body: {location_uri: location_uri},
            })
          console.log(res)
          await this.refreshOverview();
        } catch (e) {
            alert(`Error deleting location context: ${e}`)
        }
        this.setState({serverop_msg: null})
    }

    


    render(): JSX.Element {
        return (
            <AdminPage>
              <Row><Col className="pl-0"><h4>MV Bots</h4></Col></Row>
              <DialogBoxWithSpinner title={this.state.serverop_msg || ""} show={this.state.serverop_msg != null}><Spinner animation="border" variant="primary" /></DialogBoxWithSpinner>
              <br/>
              <Accordion defaultActiveKey={undefined}>
              <Card className="mt-2">
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                      <h4>Upload Actor Configs ▲▼</h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse as={Card.Body} eventKey="1">
                        <div>
                        {/* show a table of existing actor configs summary */}
                        <PageTable>
                            <thead>
                                <tr>
                                    <th>Availability</th>
                                    <th>Actor ID</th>
                                    <th>Face Icon</th>
                                    <th>Display Name</th>
                                    <th>Display Tagline</th>
                                    <th>Upload Face Icon</th>
                                    <th>Config</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.allactors_summary?.map((x) => {
                            return (
                                <PageTable.Row key={x.actor_id}>
                                    <PageTable.Cell>{x.availability}</PageTable.Cell>
                                    <PageTable.Cell>{x.actor_id}</PageTable.Cell>
                                    <PageTable.Cell width={64} ><img src={x.faceicon_url} width="64" height="64"/></PageTable.Cell>
                                    <PageTable.Cell>{x.display_name}</PageTable.Cell>
                                    <PageTable.Cell>{x.display_tagline}</PageTable.Cell>
                                    <PageTable.Cell>
                                        <FaceIconUploader actor_id={x.actor_id}/>
                                    </PageTable.Cell>
                                    <PageTable.Cell><Button variant='outline-secondary' onClick={async () => this.onActorViewClick(x.actor_id)}>View Config</Button></PageTable.Cell>
                                    <PageTable.Cell><Button variant='outline-danger' onClick={async () => this.onActorDeleteClick(x.actor_id)}>DELETE CONFIG</Button> </PageTable.Cell>
                                </PageTable.Row>
                            )
                        })}
                            </tbody>
                        </PageTable>
                        <p>
                        The <b>actor_id</b> field is used to uniquly identify a config, and new ids found in the uploaded json will be added to the complete list.<br/>
                        If an <b>actor_id</b> already exists, it will be updated.<br/>
                        The file must comprise an array named <b>actors</b> of one or many actor configs, you don't need the complete list, but an individual actor config must be complete.
                        </p>
                        <JsonUploaderWithViewer
                            post_uri={'/v2/mvbots/actor/configs'}
                            append_filename={false}
                            context={this}
                        />
                        </div>
                    </Accordion.Collapse>
                </Card>
                <br/>

                <Card className="mt-2">
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <h4>Conversation Topics ▲▼</h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse as={Card.Body} eventKey="0">
                    <div>
                        {/* show a table of existing topics summary */}
                        <p><><b>All new topics should now be entered via the MVBots settings on a metaverse/location page.</b></></p>
                        <PageTable>
                            <thead>
                                <tr>
                                    <th>Topics Collection</th>
                                    <th>Topics Count</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.alltopics_summary?.map((x) => {
                            return (
                                <PageTable.Row key={x.collection_name}>
                                    <PageTable.Cell>{x.collection_name}</PageTable.Cell>
                                    <PageTable.Cell>{x.topics_count}</PageTable.Cell>
                                    <PageTable.Cell><Button variant='outline-secondary' onClick={async () => this.onTopicsViewClick(x.collection_name)}>View Topics</Button></PageTable.Cell>
                                    <PageTable.Cell><Button variant='outline-secondary' onClick={async () => this.onTopicsDownloadClick(x.collection_name)}>Download Json</Button></PageTable.Cell>
                                    <PageTable.Cell><Button variant='outline-danger' onClick={async () => this.onTopicsDeleteClick(x.collection_name)}>DELETE TOPICS</Button> </PageTable.Cell>
                                </PageTable.Row>
                            )
                        })}
                            </tbody>
                          
                        </PageTable>
                        
                        {/* <p>
                        The <b>file name</b> (less extension) will be used as the conversation topics id.<br/>
                        If it already exists, it will be updated.<br/>
                        The file must comprise an array named <b>topics</b>, containing multiple strings.
                        </p>
                        <JsonUploaderWithViewer
                            post_uri={'/v2/mvbots/conversation/topics'}
                            append_filename={true}
                            context={this}
                        /> */}
                    </div>
                    </Accordion.Collapse>
                </Card>
                <br/>

                <Card className="mt-2">
                  <Accordion.Toggle as={Card.Header} eventKey="2">
                      <h4>Location Contexts ▲▼</h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse as={Card.Body} eventKey="2">
                      <div>
                      {/* show a table of existing location contexts summary */}
                      <PageTable>
                          <thead>
                              <tr>
                                  <th>Location Context URI</th>
                                  <th>View Json</th>
                                  <th>Generate & View Convo</th>
                                  <th>DANGER!</th>
                              </tr>
                          </thead>
                          <tbody>
                          {this.state.allcontexts_summary?.map((x) => {
                          return (
                              <PageTable.Row key={x.location_uri}>
                                  <PageTable.Cell>{x.location_uri}</PageTable.Cell>
                                  <PageTable.Cell><Button variant='outline-secondary' onClick={async () => this.onContextViewClick(x.location_uri)}>View Json</Button></PageTable.Cell>
                                  <PageTable.Cell><Button variant='outline-secondary' onClick={async () => this.onContextGenerateClick(x.location_uri)}>Generate & View Convo</Button></PageTable.Cell>
                                  
                                  <PageTable.Cell>
                                    <OverlayTrigger placement="top" overlay={<Tooltip id='outline-danger'>Deletes all previous location conversations, and generates one new one.</Tooltip>}>
                                    <Button variant='outline-danger' onClick={async () => this.onContextResetClick(x.location_uri)}>RESET Context</Button>
                                  </OverlayTrigger>
                                  </PageTable.Cell>
                                  <PageTable.Cell><Button variant='danger' onClick={async () => this.onContextDeleteClick(x.location_uri)}>DELETE CONTEXT</Button> </PageTable.Cell>
                              </PageTable.Row>
                          )})}
                          </tbody>
                      </PageTable>
                      {/* <p>
                      Upload a json file containing a single location context object
                      </p>
                      <JsonUploaderWithViewer
                          post_uri={'/v2/mvbots/location/context'}
                          append_filename={false}
                          context={this}
                      /> */}
                      </div>
                  </Accordion.Collapse>
                </Card>
                <br/>

                <Card className="mt-2">
                  <Accordion.Toggle as={Card.Header} eventKey="3">
                      <h4>System Prompts ▲▼</h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse as={Card.Body} eventKey="3">
                  <Accordion defaultActiveKey={undefined}>
                  <div>
                      These are the allowed subsitutions for system prompts, which are automatically retrieved:<br/>
                      <pre>
                      <b>{"${selected_personalities}"}</b> - List of the randomly selected actors and persoanlities<br/>
                      <b>{"${domain_name}"}</b> - Name of the domain (metaverse) the location is in.<br/>
                      <b>{"${domain_desc}"}</b> - Description of the domain (metaverse) the location is in.<br/>
                      <b>{"${domain_owner}"}</b> - Name of the owner of the domain (metaverse) the location is in.<br/>
                      <b>{"${location_name}"}</b> - Name of the location (room).<br/>
                      <b>{"${location_desc}"}</b> - Description of the location (room).<br/>
                      <b>{"${combined_topic}"}</b> - Randomly selected and possibly combined topic for the conversation.<br/>
                      <b>{"${start_actor_display_name}"}</b> - Name of the actor that starts the conversation.
                      </pre>
                      If the data is not available (eg. a location description), then the <b>entire line</b> the subsitution is on will be removed!
                      <br/><br/>
                      {/* show editable cards for each prompt, ensure updated prommpts are saved into all prompts */}
                      {this.state.allprompts?.map((x) => {
                        return (
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={x.prompt_id}>{x.prompt_id}: {x.title}  ▲▼</Accordion.Toggle>
                            <Accordion.Collapse as={Card.Body} eventKey={x.prompt_id}>
                              <div>
                              <Button variant='outline-secondary' onClick={async () => {
                                this.setState({serverop_msg: "Generating preview..."})
                                // send preview prompt to server
                                try {
                                  const res = await this.props.multiverse.post<any>({
                                    url: `/v2/mvbots/conversation/preview`,
                                    body: { system_prompt: x.system_prompt, location_uri:"ftl.multiverseonline.io/locations/myth_skygods" }
                                  })
                                  let win = window.open("", "_blank");
                                  win?.document.write(`<pre>${JSON.stringify(res, null, 2)}</pre>`);
                                } catch (e) {
                                    alert(`Error sending prompt to server: ${e}`)
                                }
                                this.setState({serverop_msg: null})
                                }}>
                              Preview</Button>
                              <Button variant='primary' onClick = {async () => {
                                this.setState({serverop_msg: "Saving system prompt..."})
                                // send prompt to server
                                try {
                                  // encode prompt text to json first
                                  //x.system_prompt = JSON.stringify(x.system_prompt).slice(1, -1)
                                  const res = await this.props.multiverse.post<any>({
                                      url: `/v2/mvbots/systemprompt`,
                                      body: x,
                                  })
                                  console.log(res)
                                  await this.refreshOverview();
                                } catch (e) {
                                    alert(`Error sending prompt to server: ${e}`)
                                }
                                this.setState({serverop_msg: null})
                              }}>Send to Server</Button>
                              <Form>
                                <Form.Group>
                                  <Form.Label>Prompt ID - <b>Warning! Changing this will result in a NEW prompt being created!</b></Form.Label>
                                  <Form.Control type="text" value={x.prompt_id} onChange={(e) => {
                                    // update prompt_id in prompt
                                    let newprompts = this.state.allprompts?.map((p) => {
                                      if (p.title === x.title) {
                                          return {...p, prompt_id: e.target.value}
                                      }
                                      return p
                                    })
                                    if(newprompts)
                                      this.setState({allprompts: newprompts})
                                  }}/>
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>Title</Form.Label>
                                  <Form.Control type="text" value={x.title} onChange={(e) => {
                                    // update title in prompt, and set in all prompts state
                                    let newprompts = this.state.allprompts?.map((p) => {
                                      if (p.title === x.title) {
                                          return {...p, title: e.target.value}
                                      }
                                      return p
                                    })
                                    if(newprompts)
                                      this.setState({allprompts: newprompts})
                                  }}/>
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>System Prompt</Form.Label>
                                    <TextareaAutosize className="form-control" value={x.system_prompt} onChange={(e) => {
                                      let newprompts = this.state.allprompts?.map((p) => {
                                          if (p.title === x.title) {
                                              return {...p, system_prompt: e.target.value}
                                          }
                                          return p
                                      })
                                      if(newprompts)
                                        this.setState({allprompts: newprompts})
                                    }}/>
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>User Prompt</Form.Label>
                                    <TextareaAutosize className="form-control" value={x.user_prompt} onChange={(e) => {
                                      let newprompts = this.state.allprompts?.map((p) => {
                                          if (p.title === x.title) {
                                              return {...p, user_prompt: e.target.value}
                                          }
                                          return p
                                      })
                                      if(newprompts)
                                        this.setState({allprompts: newprompts})
                                    }}/>
                                </Form.Group>
                              </Form>
                              </div>
                            </Accordion.Collapse>
                          </Card>
                        ) 

                      })}
                      </div>
                  </Accordion>
                  </Accordion.Collapse>
                </Card>

              </Accordion>

            </AdminPage>
        )
    }
}

export const AdminMVBotsPage = withRouter(withMultiverseApi(_AdminMVBotsPage));

export default AdminMVBotsPage;

