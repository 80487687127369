import React , {useEffect, useRef, useState} from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseFile, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { Accordion, Breadcrumb, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import PageTable from '../../components/pageTable';
import TextareaAutosize from 'react-textarea-autosize';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import ReactJson from 'react-json-view';
import api from '../../utils/api';
import { FileDrop } from 'react-file-drop';
import { on } from 'process';
import DialogBox from '../../components/dialogBox';
import DialogBoxWithSpinner from '../../components/dialogBoxWithSpinner';
import { th } from 'date-fns/locale';
import { propTypes } from 'react-bootstrap/esm/Image';
import { max } from 'date-fns';
import { AgentsAdminOverviewCard } from '../../components/agentsadmin/agentsAdminOverviewCard';

type AdminAgentsPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type AdminAgentsPageState = {
    serverop_msg: string | null
};

interface Module {
    _id?: string;
    value: string;
}

interface ModuleType {
  _id?: string;
  cost_mc?: number;
  max_usage?: number;
  triggers_faceprompt?: boolean;
  ai_prompt?: string;
  display_name?: string;
  modules?: Module[];
  iconblob_url?: string;
}


interface IconBlobUploaderProps {
  moduletype_id: string;
  setRefresh:React.Dispatch<React.SetStateAction<boolean>>;
}
const IconBlobUploader: React.FC<IconBlobUploaderProps> = (props) =>
{
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
        upload(files);
    }
  }

  const onDropFiles = (files: FileList | null, event: React.DragEvent<HTMLDivElement>) => {
    if (files) {
        upload(files);
    }
  }

  const resetUploadInput = () => {
    if(fileInputRef.current)
        fileInputRef.current.value = "";
  }


  const upload = async(files: FileList) => {
    // upload face icon as blob
    const file = files[0];
    try {
        const response = await api.post<MultiverseFile>({
            url: `/v2/admin/agents/moduletypeicon/${props.moduletype_id}`,
            headers: {
              'Content-Type': file.type,
              'x-shapevr-name': file.name,
              },
            body: file,
        });
        if (response) {
          props.setRefresh((prev) => !prev);
          alert('Face Icon uploaded successfully!');
        } else {
            alert('Failed to upload Face Icon.');
        }
    } catch (error) {
        alert(`Error uploading Face Icon:\n${error}`);
    }
    resetUploadInput();
  }

  return (
    <FileDrop 
      className="align-items-center"
      onDrop={onDropFiles}>
        <input
            onChange={onFileInputChange}
            ref={fileInputRef}
            type="file"
            accept="image/*"
        />
    {/* <Button variant='outline-secondary' onClick={async () => {fileInputRef.current?.click()}}>Upload Icon</Button> */}
    Manually refresh the page after choosing file!
    </FileDrop>
  );
}


interface ModuleTypeEditorProps {
  selectedId: string | undefined;
  handleSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  moduleTypes: ModuleType[];
  currentModuleType: ModuleType;
  setCurrentModuleType: React.Dispatch<React.SetStateAction<ModuleType>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setServerop_msg: React.Dispatch<React.SetStateAction<string | null>>;
}

const ModuleTypeEditor: React.FC<ModuleTypeEditorProps> = ({
  selectedId,
  handleSelect,
  moduleTypes,
  currentModuleType,
  setCurrentModuleType,
  setRefresh,
  setServerop_msg,
}) => {

  const [newModuleValues, setNewModuleValues] = useState<Module[]>([]); // new modules without ids
  
  const onSendToServerClicked = async () => {
    try {
      setServerop_msg("Sending to server...");
      const res = await api.post<any>(`/v2/admin/agents/moduletype`, {
        _id: currentModuleType._id,
        ai_prompt: currentModuleType.ai_prompt,
        display_name: currentModuleType.display_name,
        // join current and  new modules
        modules: [...(currentModuleType.modules || []), ...newModuleValues],
        cost_mc: currentModuleType.cost_mc,
        max_usage: currentModuleType.max_usage,
        triggers_faceprompt: currentModuleType.triggers_faceprompt,
      });
      console.log(res);
      setRefresh((prev) => !prev);
      setNewModuleValues([]);
    } catch (e) {
      alert(e);
    }
    setServerop_msg("");
  };


  return (
    <Card>
      <Card.Header className="d-flex align-items-center justify-content-between">
        <div>Module Type Editor&nbsp;&nbsp;
        <select key="elements_dropdown" value={selectedId} onChange={handleSelect}>
          {moduleTypes.map((moduletype: ModuleType) => (
            <option key={moduletype._id} value={moduletype._id}>{moduletype.display_name}</option>
          ))}
          <option key="_new" value="_new">{"<new...>"}</option>
        </select>
        {currentModuleType._id && <small>({currentModuleType._id})</small>}

        </div>
        <Button variant="primary" onClick={onSendToServerClicked}>Send to Server</Button>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group as={Row} controlId="display_name" className="align-items-center">
            <Col sm="2"><Form.Label>Display Name:</Form.Label></Col>
            <Col><Form.Control
              value={currentModuleType.display_name || ""}
              onChange={(e) => setCurrentModuleType({ ...currentModuleType, display_name: e.target.value })}
            /></Col>
          </Form.Group>
          <Form.Group as={Row} controlId="ai_input" className="align-items-center">
            <Col sm="2"><Form.Label>AI Prompt:</Form.Label></Col>
            <Col><Form.Control
              value={currentModuleType.ai_prompt || ""}
              onChange={(e) => setCurrentModuleType({ ...currentModuleType, ai_prompt: e.target.value })}
            /></Col>
          </Form.Group>
          <Form.Group as={Row} controlId="cost_mc" className='d-flex align-items-center'>
          <Col sm="2"><Form.Label>Cost MC</Form.Label></Col>
            <Col><Form.Control
              type="number"
              value={currentModuleType.cost_mc || 0}
              onChange={(e) => setCurrentModuleType({ ...currentModuleType, cost_mc: parseInt(e.target.value) })}
              min="0"
              step="1"
            /></Col>
          </Form.Group>
          <Form.Group  as={Row}  controlId="max_usage" className='d-flex align-items-center'>
          <Col sm="2"><Form.Label>Max Usages</Form.Label></Col>
            <Col><Form.Control
              type="number"
              value={currentModuleType.max_usage || 0}
              onChange={(e) => setCurrentModuleType({ ...currentModuleType, max_usage: parseInt(e.target.value) })}
              min="0"
              step="1"
            /></Col>
          </Form.Group>
          <Form.Group as ={Row} controlId="triggers_faceprompt" className='d-flex align-items-center'>
            <Col sm="2"><Form.Label>Triggers Faceprompt</Form.Label></Col>
            <Col><input
              type="checkbox"
              checked={currentModuleType.triggers_faceprompt ?? false}
              onChange={(e) => setCurrentModuleType({ ...currentModuleType, triggers_faceprompt: (e.target as HTMLInputElement).checked })}
            /></Col>
          </Form.Group>
          <Form.Group as={Row} controlId='icon' className='d-flex align-items-center'>
            <Col sm="2"><img src={currentModuleType.iconblob_url} width="64" height="64"/></Col>
            <Col>
              <IconBlobUploader moduletype_id={currentModuleType._id || ""} setRefresh={setRefresh} />
            </Col>
          </Form.Group>
          <Accordion defaultActiveKey={undefined}>
            <Form.Group>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                  ▲▼ Available Modules {`${currentModuleType.modules?.length}`} (in the form of Display Name : AI Prompt)
              </Accordion.Toggle>
              {/* <Form.Label>Available Modules (in the form of Display Name : AI Prompt)</Form.Label> */}
              <Accordion.Collapse eventKey="1"><>
              { currentModuleType.modules?.map((module, index) => (
                <Form.Group key={index} controlId={`module_${index}`} style={{ marginTop: 0, marginBottom: 2 }}>
                  <Form.Control
                    value={module.value}
                    onChange={(e) => {
                      const newModules = currentModuleType.modules?.map((m, i) => {
                        if (i === index) {
                          return { _id: m._id, value: e.target.value }; // ensure to keep id!
                        }
                        return m;
                      });
                      setCurrentModuleType({ ...currentModuleType, modules: newModules });
                    }}
                  />
                </Form.Group>
              ))}
              </></Accordion.Collapse>
              </Form.Group>
            </Accordion>            
            <Form.Group>
            <Form.Label>Add new modules here... (in the form of Display Name : AI Prompt)</Form.Label>
            <Form.Control
              as="textarea"
              rows={16}
              value={newModuleValues?.map((newmodule) => newmodule.value).join("\n") || ""}
              onChange={(e) => {
                const newModules = e.target.value.split("\n").map((value) => ({ value }));
                setNewModuleValues(newModules); 
              }}
            />
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};



function _AdminAgentsPage( props: AdminAgentsPageProps) {
  const [serverop_msg, setServerop_msg] = useState<string | null>(null);
  const [moduleTypes, setModuleTypes] = useState<any[]>([]);
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [currentModuleType, setCurrentModuleType] = useState<ModuleType>({});
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    const loadModuleTypes = async () => {
      try {
        setServerop_msg("Loading...");
        const res = await api.get<any[]>(`/v2/admin/agents/moduletypes`);
        setModuleTypes(res);
        setSelectedId(res[0]?._id || "");
        setCurrentModuleType(res[0]);
      } catch (e) {
        alert(e);
      }
      setServerop_msg("");
    };
    loadModuleTypes();
  }, [refresh]);

  useEffect(() => {
    const loadModuleType = async () => {
    try {
      // load selected module type, and associated modules
      const res = await api.get<ModuleType>(`/v2/admin/agents/moduletype/${selectedId}`);
      setCurrentModuleType(res);
    } catch (e) {
      alert(e);
    }}
    if(selectedId) {
      loadModuleType();
    } else {
      setCurrentModuleType({});
    }
  }, [selectedId])
    

  const handleSelect = (e: any) => {
    let index = moduleTypes.findIndex((element) => element.type === e.target.value);
    if (e.target.value === "_new") {
      setSelectedId(undefined);
    } else {
      setSelectedId(e.target.value);
    }
  };

  return (
    <AdminPage>
      <h4><b><Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin/agents" }}>Agents</Breadcrumb.Item>
          <Breadcrumb.Item active><b>Manage Modules</b></Breadcrumb.Item>
      </Breadcrumb></b></h4>
      <DialogBoxWithSpinner title={serverop_msg || ""} show={!!serverop_msg}>
        <Spinner animation="border" variant="primary" />
      </DialogBoxWithSpinner>
      <br />
        <ModuleTypeEditor
          selectedId={selectedId}
          handleSelect={handleSelect}
          moduleTypes={moduleTypes}
          currentModuleType={currentModuleType}
          setCurrentModuleType={setCurrentModuleType}
          setRefresh={setRefresh}
          setServerop_msg={setServerop_msg}
        />
    </AdminPage>
  );
}
export const AdminAgentsModulesPage = withRouter(withMultiverseApi(_AdminAgentsPage));

export default AdminAgentsModulesPage;

